import React from "react";

const IconLightning = props => (
	<svg viewBox="0 0 14.219 23.186" {...props}>
		<path
			fill="none"
			stroke="currentColor"
			strokeLinejoin="round"
			strokeWidth={2}
			d="M15.719 3.5H9.754L4.285 14.314h3.54L3.5 24.686l11.461-13.968-4.325-.018z"
			data-name="Trac\xE9 17"
			transform="translate(-2.5 -2.5)"
		/>
	</svg>
);

export default IconLightning;
