import React from "react";

const IconListview = props => (
	<svg viewBox="0 0 15 15" {...props}>
		<style>
			{".st0{fill:currentColor}.st1{display:none}.st2{display:inline,fill:currentColor}"}
		</style>
		<g id="Calque_1">
			<g id="Calque_3">
				<path
					className="st0"
					d="M14,4.8H1c-0.5,0-0.9-0.4-0.9-0.9V2c0-0.5,0.5-0.9,0.9-0.9h13c0.5,0,0.9,0.5,0.9,0.9v1.8
			C14.9,4.4,14.6,4.8,14,4.8z"
				/>
				<path
					className="st0"
					d="M14,9.4H1C0.5,9.4,0.1,9,0.1,8.5V6.6c0-0.6,0.5-0.9,0.9-0.9h13c0.5,0,0.9,0.4,0.9,0.9v1.8
			C14.9,8.9,14.6,9.4,14,9.4z"
				/>
				<path
					className="st0"
					d="M14,14H1c-0.5,0-0.9-0.4-0.9-0.9v-1.8c0-0.6,0.5-0.9,0.9-0.9h13c0.5,0,0.9,0.4,0.9,0.9v1.8
			C14.9,13.6,14.6,14,14,14z"
				/>
			</g>
		</g>
		<g id="Calque_2" className="st1">
			<path
				className="st2"
				d="M5.6,6.7H1.5c-0.6,0-1-0.4-1-1V2.1c0-0.6,0.4-1,1-1h4.1c0.6,0,1,0.4,1,1v3.6C6.6,6.3,6.2,6.7,5.6,6.7z"
			/>
			<path
				className="st2"
				d="M13.6,6.7H9.4c-0.6,0-1-0.4-1-1V2.1c0-0.6,0.4-1,1-1h4.1c0.6,0,1,0.4,1,1v3.6C14.6,6.3,14.1,6.7,13.6,6.7z"
			/>
			<path
				className="st2"
				d="M5.6,14H1.5c-0.6,0-1-0.4-1-1V9.4c0-0.6,0.4-1,1-1h4.1c0.6,0,1,0.4,1,1V13C6.6,13.6,6.2,14,5.6,14z"
			/>
			<path
				className="st2"
				d="M13.6,14H9.4c-0.6,0-1-0.4-1-1V9.4c0-0.6,0.4-1,1-1h4.1c0.6,0,1,0.4,1,1V13C14.6,13.6,14.1,14,13.6,14z"
			/>
		</g>
	</svg>
);

export default IconListview;
