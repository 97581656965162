import React from "react";

const SvgIconShareWhite3 = props => (
	<svg id="Calque_1" x={0} y={0} viewBox="0 0 55 71" xmlSpace="preserve" {...props}>
		<style>{".st1{fill:currentColor}"}</style>
		<path
			d="M40.4,28h-8.2c-1.1,0-2,0.9-2,2s0.9,2,2,2h8.2c0.2,0,0.3,0,0.3,0.3v20.3c0,0.2,0,0.3-0.3,0.3H7.9 c-0.2,0-0.3,0-0.3-0.3V32.2c0-0.2,0-0.3,0.3-0.3h8.2c1.1,0,2-0.9,2-2s-0.9-2-2-2H7.9c-2.5,0-4.3,1.8-4.3,4.3v20.4 c0,2.5,1.8,4.3,4.3,4.3h32.5c2.5,0,4.3-1.8,4.3-4.3V32.3C44.7,29.8,42.9,28,40.4,28z"
			className="st1"
		/>
		<path
			d="M16.1,14.9c0.4,0,0.9-0.1,1.3-0.4l4.8-3.9v28.1c0,1.1,0.9,2,2,2s2-0.9,2-2V10.6l4.7,3.9 c0.9,0.7,2.1,0.6,2.8-0.3s0.6-2.1-0.3-2.8l-8-6.5c-0.7-0.6-1.8-0.6-2.5,0l-8.1,6.5c-0.9,0.7-1,2-0.3,2.8 C14.9,14.6,15.5,14.9,16.1,14.9z"
			className="st1"
		/>
	</svg>
);

export default SvgIconShareWhite3;
