import React from "react";

const SvgIconMastercard = props => (
	<svg id="Calque_1" x={0} y={0} viewBox="0 0 511 313" xmlSpace="preserve" {...props}>
		<g id="Calque_4">
			<g id="XMLID_3_">
				<path
					d="M482.9,123l-12.4,66.5h-15.3l-1.8-4.2c-2.9,2-7.7,4-12.4,4.2c-10.2,0-19.6-14.4-15-30.3 c6.9-23.7,19.2-22.3,27-22.3c6.8,0,8.4,4.2,11.1,6.2l3.8-20.1L482.9,123L482.9,123z M459,164.5c1.5-4.8,0.7-10.7-0.9-12.8 c-4.4-5.8-14.5-1.2-17.5,10.2c-2,7.5,2.4,13.1,7.3,13.1C453.2,175.1,456.5,170.9,459,164.5z"
					fill="currentColor"
				/>
				<path
					d="M354,3.5c84.4,0,152.8,68.4,152.8,152.8S438.4,309.1,354,309.1c-37,0-71-13.2-97.4-35.1 c-26.5,22-60.6,35.1-97.7,35.1c-84.4,0-152.8-68.4-152.8-152.8S74.5,3.5,158.9,3.5c37.1,0,71.2,13.1,97.7,35.1 C283,16.7,317,3.5,354,3.5z M495.6,156.3c0-78.2-63.4-141.6-141.6-141.6c-33.9,0-64.8,11.9-89.2,31.8 c18.6,18,32.8,40.4,40.4,65.9h-11.8c-7.4-22.6-20.3-42.5-37.1-58.5c-16.7,15.9-29.6,36-36.9,58.4h-11.8 c7.6-25.3,21.5-47.8,40-65.7c-24.3-19.7-55-31.9-88.8-31.9c-78.2,0-141.6,63.4-141.6,141.6s63.4,141.6,141.6,141.6 c33.7,0,64.5-12.2,88.8-31.9c-18.5-17.9-32.4-40.4-40-65.7h11.8c7.3,22.5,20.2,42.5,36.9,58.5c16.8-16,29.8-35.9,37.1-58.5h11.8 c-7.6,25.4-21.8,47.9-40.4,65.8c24.4,19.9,55.2,31.8,89.2,31.8C432.2,297.8,495.6,234.5,495.6,156.3z"
					fill="currentColor"
				/>
				<path
					d="M428.6 137.1l-3.9 13.6c-3.5 0-10.2-.9-14.4 14.8l-4.4 24h-14.4l9.8-52.4h12.4l1.3 5.2C417.1 139.6 421.4 137.1 428.6 137.1zM371.6 136.7c23.9 0 21.5 17.2 20.1 24.3l-5.7 28.5h-13.3l-1.8-2.9c-2.6 1.8-4.6 2.7-11.9 3.1-7.3.4-16.6-5.1-15-15.9 4-21.9 29.6-17.9 33.2-18.3 1.7-7.9-3.8-7.1-8.8-7.3s-14.2 1.3-17.7 1.6l2.2-11.3c0 0 .2-.1 1-.2h.1c.2 0 .4-.1.6-.1C362.8 136.7 367.4 136.9 371.6 136.7zM373.7 171.8l1.1-5.7c-2.4-.4-7.7-1.8-12.2 1.3-4.6 3.1-5.8 8.6-1.1 10.4C366.1 179.6 372.8 176.5 373.7 171.8z"
					fill="currentColor"
				/>
				<path
					d="M354.6,138.2c-0.2,0-0.4,0.1-0.6,0.1C353.8,138.4,354.1,138.3,354.6,138.2z"
					fill="currentColor"
				/>
				<path
					d="M352 131.6l-9.3 9.3c-3.6-2.2-6.2-2.6-11-2.6-4.7 0-15.3 4.5-17.3 17.3-2.4 15.2 8 22.6 22.6 17.7l4.2 12.8c-4.8 2.2-9.5 3.3-17.4 3.3-14.8 0-30.9-13.1-23.5-39.8 5.4-19.6 22.3-26.8 32.1-26.5C344.2 123.6 348.4 127.6 352 131.6zM300.9 137.6l-3.6 13.7c-3.5 0-10.2-1.1-14.4 14.4l-4.4 23.7h-14.2l9.7-51.8h12.2l1.3 5.1C289.6 140.2 293.8 137.6 300.9 137.6zM233.9 155.9l-.1.4c0 .1-.1.3-.1.4h17.7c.9-5.1-.7-9.5-6.4-9.5-5.5 0-9.4 2.7-11.2 8.9C233.9 156 233.9 155.9 233.9 155.9zM244.3 136.9c25 0 21.2 20.8 19.5 29.2h-31c-1.6 11.9 7.5 11.5 13.1 11.5 5.7 0 13.9-.7 16.1-1.3l-2.2 10.8c-3.5 1.8-12.2 2.6-18.3 2.4-24.7 0-24.3-23.2-23.7-26.5C218.4 159.7 221.3 136.9 244.3 136.9z"
					fill="currentColor"
				/>
				<path d="M233.9,155.9v0.1c0,0.1,0,0.2-0.1,0.2L233.9,155.9z" fill="currentColor" />
				<path
					d="M221.1 137.6l-2.5 11.3h-7.3l-4 21.2c-.7 4-.4 7.7 7.9 6.4l-.9 10.6c-3.1 2-7.3 2.6-10.6 2.6-3.3 0-14.1-2-11.3-16.2l9.9-49.7h14.1l-2.9 13.9L221.1 137.6 221.1 137.6zM188.4 149.3c-4-1-10.6-1-13.8-.7-5.3 1.5-3.1 6.4-.2 7.7 2.9 1.3 7.5 3.8 10.2 5.7 2.7 1.6 6.1 5.1 4.9 12.8-2.4 15.7-19.4 14.8-22.8 14.8-4.9 0-10.2 0-16.8-1.8l2-10.8c7.1 2.4 16.6 1.3 18.8 1.3 4.8 0 5.4-6 1.8-7.9-2.9-1.5-6-3.6-11.5-6-3.4-1.5-6.9-5.5-4.9-15.1 2.6-12.6 19.7-12.4 21.7-12.4s7.3-.5 14.1 2l-3.3 10.6C188.5 149.3 188.4 149.3 188.4 149.3zM130.5 136.7c23.9 0 21.6 17.2 20.1 24.3l-5.7 28.5h-13.3l-1.8-2.9c-2.6 1.8-4.6 2.7-11.9 3.1s-16.6-5.1-15-15.9c4-21.9 29.6-17.9 33.2-18.3 1.6-7.9-3.8-7.1-8.8-7.3-4.9-.2-14.2 1.3-17.7 1.6l2.2-11.3c0 0 .2 0 1-.2h.1C121.6 136.7 126.3 136.9 130.5 136.7zM132.7 171.8l1.1-5.7c-2.4-.4-7.7-1.8-12.2 1.3-4.6 3.1-5.8 8.6-1.1 10.4C125 179.6 131.8 176.5 132.7 171.8zM108.1 126.3L96.2 189.5 81.4 189.5 89.8 144.9 69.9 189.5 56.4 189.5 52.5 143.8 43.8 189.5 29.5 189.5 41.8 126.3 64.6 126.3 67.3 164.7 84.7 126.3z"
					fill="currentColor"
				/>
			</g>
		</g>
	</svg>
);

export default SvgIconMastercard;
