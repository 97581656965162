import React from "react";

const SvgIconPaiement2x = props => (
	<svg id="Calque_1" x={0} y={0} viewBox="0 0 201.8 143.1" xmlSpace="preserve" {...props}>
		<path
			fill="#fff"
			stroke="currentcolor"
			strokeMiterlimit={10}
			strokeWidth={11}
			d="M10,37h148c2.5,0,4.5,2,4.5,4.5V133c0,2.5-2,4.5-4.5,4.5H10c-2.5,0-4.5-2-4.5-4.5V41.6C5.5,39.1,7.5,37,10,37z"
		/>
		<path d="M5.5 59.5H161.7V80H5.5z" />
		<circle
			cx={146}
			cy={55.8}
			r={51.3}
			fill="#fff"
			stroke="currentcolor"
			strokeMiterlimit={10}
			strokeWidth={9}
		/>
		<path d="M178.8 36.3L167.2 54l12.6 19h-10.1l-6.6-10.9-1.4-2.5-1.5 2.6L153.4 73h-10.1l13.1-19.7-11.2-17h9.8l5.5 8.7 1.5 2.6 1.6-2.7 5.4-8.6H178.8zM110 43.9c4.3-6.6 8.8-8.6 14.8-8.6 6.9 0 12.8 4.3 12.8 11.8 0 7.1-4.2 11.3-8.3 15.9l-1.8 2h11.6v8H110v-1.1l2.1-2.5c10.6-12.5 15.8-16.8 15.8-21.8 0-2.8-1.7-4.3-4.4-4.3-4.3 0-6.4 4-7.2 5.6L110 43.9z" />
	</svg>
);

export default SvgIconPaiement2x;
