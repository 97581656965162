import React from "react";

const SvgComponent = props => (
	<svg viewBox="0 0 132.6 240.82" {...props}>
		<path
			fill="currentColor"
			d="M57.63,129l108.3,108.26a12.16,12.16,0,1,0,17.21-17.18L83.44,120.42l99.7-99.67A12.16,12.16,0,0,0,165.92,3.56L57.62,111.82A12.27,12.27,0,0,0,57.63,129Z"
			transform="translate(-54.11)"
		/>
	</svg>
);

export default SvgComponent;
