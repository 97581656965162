import React from "react";
import { connect } from "react-redux";
import SocialBar from "./SocialBar";

const SocialBarComponent = props => <SocialBar {...props} />;

const mapStateToProps = state => {
	return {
		resolution: state.resolution,
		partnerData: state.partnerData,
	};
};

export default connect(mapStateToProps)(SocialBarComponent);
