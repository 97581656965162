import React from "react";

const SvgIconHelp = props => (
	<svg viewBox="0 0 19.5 19" {...props}>
		<defs>
			<style>
				{".cls-1{fill:none;stroke:currentColor;stroke-miterlimit:10;stroke-width:1px}"}
			</style>
		</defs>
		<path
			d="M8.5 5L7.4 1M11.1 5.2L12.1 1.1M14.3 8.3L18.3 7.2M14.1 10.9L18.2 11.9M5.2 10.7L1.2 11.8M5.4 8.1L1.3 7.1M11 14L12.1 18M8.4 13.8L7.4 17.9"
			className="cls-1"
		/>
		<circle cx={9.8} cy={9.5} r={4.5} className="cls-1" />
		<circle cx={9.8} cy={9.5} r={8.7} className="cls-1" />
	</svg>
);

export default SvgIconHelp;
