import React from "react";

const IconCardview = props => (
	<svg viewBox="0 0 15 15" {...props}>
		<style>{".st2{fill:currentColor}.st3{fill:currentColor}"}</style>
		<g id="Calque_2">
			<path
				d="M5.6 6.7H1.5c-.6 0-1-.4-1-1V2.1c0-.6.4-1 1-1h4.1c.6 0 1 .4 1 1v3.6C6.6 6.3 6.2 6.7 5.6 6.7zM13.6 6.7H9.4c-.6 0-1-.4-1-1V2.1c0-.6.4-1 1-1h4.1c.6 0 1 .4 1 1v3.6C14.6 6.3 14.1 6.7 13.6 6.7zM5.6 14H1.5c-.6 0-1-.4-1-1V9.4c0-.6.4-1 1-1h4.1c.6 0 1 .4 1 1V13C6.6 13.6 6.2 14 5.6 14zM13.6 14H9.4c-.6 0-1-.4-1-1V9.4c0-.6.4-1 1-1h4.1c.6 0 1 .4 1 1V13C14.6 13.6 14.1 14 13.6 14z"
				className="st3"
			/>
		</g>
	</svg>
);

export default IconCardview;
