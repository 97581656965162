import React from "react";

const IconProfil = props => (
	<svg viewBox="0 0 19.5 19" {...props}>
		<defs>
			<style>
				{
					".icon-profile{fill:none;stroke:currentColor;stroke-miterlimit:10;stroke-width:1px}"
				}
			</style>
		</defs>
		<circle cx={9.75} cy={7.11} r={3.24} className="icon-profile" />
		<path
			d="M3.63,15.46a7,7,0,0,1,3-4.37,4.86,4.86,0,0,0,5.94.06l.08-.06A6,6,0,0,1,16,15.35"
			className="icon-profile"
		/>
		<circle cx={9.75} cy={9.5} r={8.55} className="icon-profile" />
	</svg>
);

export default IconProfil;
