import React from "react";

const SvgIconVisa = props => (
	<svg id="Calque_2" x={0} y={0} viewBox="0 0 522 173" xmlSpace="preserve" {...props}>
		<g id="Calque_3">
			<g id="XMLID_2_">
				<path
					d="M516.6 166c-1.7.2-2.9.4-4.1.4-10.3 0-20.5-.1-30.8 0-2.5 0-3.2-.9-3.7-3.2-1.1-6.3-2.5-12.5-4-18.7-.2-.9-1.8-2.2-2.8-2.2-16.2-.2-32.5-.2-48.7 0-1 0-2.6 1.3-3 2.4-2.5 6.4-4.7 13-7.2 19.4-.3.9-1.7 2.1-2.6 2.1-8.9.1-17.7.1-26.6.1-4 0-7.9 0-11.9 0-.7 0-1.4-.3-2.5-.6 1.2-3.1 2.3-5.9 3.5-8.7 19.2-46 38.5-91.9 57.7-137.9 3.6-8.6 9.8-13 19.3-12.8 11.2.2 22.4 0 34 0C494.5 59.8 505.5 112.7 516.6 166zM432.2 109.6c11.7 0 22.9 0 34.6 0-4.1-19.9-8.2-39.5-12.3-59-.3 0-.6-.1-.9-.1C446.6 70.1 439.5 89.7 432.2 109.6zM376 9.8c.7.2 1.7 1.7 1.5 2.4-2 10-4.2 20-6.4 30-.1.5-.4 1-.5 1.4-6.3-1.8-12.5-4.1-18.9-5.4-9.2-1.9-18.6-2.7-27.8.3-2.8.9-5.7 2.6-7.9 4.6-5 4.4-5.3 10.7-.2 15 4.6 3.9 10 6.9 15.1 10.1 7.1 4.4 14.6 8 21.4 12.8 21.6 15 21.8 39.5 11.6 57.9-8 14.5-21.4 22.3-36.9 26.4-6.9 1.8-14.2 2.3-21.4 3.4-1.3.2-2.6.5-4 .7-1.7 0-3.4 0-5.1 0-4.6-.5-9.3-.9-13.9-1.5-10.3-1.2-20.4-3.2-30.2-7.4 2.5-11.6 4.9-23 7.4-34.8 2.1.9 4 1.7 5.9 2.6 13.1 6 26.9 8.4 41.1 7 4.7-.5 9.6-2.2 13.8-4.5 8-4.5 9.1-14.1 2.2-20.1-4.2-3.7-9.3-6.3-14.1-9.3-7.9-4.9-16.3-9-23.6-14.6-23.1-17.7-17.8-46.9-2.7-62.5 11.1-11.5 25-17 40.4-19.6 2.8-.5 5.6-.9 8.4-1.4 5.3 0 10.7 0 16 0 5.1.9 10.3 1.6 15.3 2.7C367 7 371.5 8.4 376 9.8zM216.6 6.6c13.7 0 27.3 0 41.1 0-2 9.4-3.9 18.6-5.9 27.9-9.1 42.8-18.3 85.5-27.3 128.3-.6 2.6-1.4 3.9-4.5 3.8-11.3-.2-22.6-.1-34-.1-1 0-2.1 0-3.6 0C193.9 112.9 205.2 59.8 216.6 6.6zM198.4 6.8c-.4 1-.8 1.9-1.1 2.9-21.5 51.4-43 102.8-64.4 154.2-.9 2.1-2.1 2.8-4.3 2.8-12.3-.1-24.6-.2-36.9 0-3 .1-4-1-4.7-3.7-8.5-33.2-17.1-66.4-25.6-99.5C59 54.3 56.6 45.2 54.3 36c-1.2-4.6-3.6-8.5-7.9-10.8C33.1 18.1 18.9 13.9 4.2 11c0-.4 0-.8 0-1.3.4-1 .8-1.9 1.3-3.3 1.3 0 2.8 0 4.2 0 21.3 0 42.5.2 63.8-.1C85.1 6.1 92.8 12.4 95 23.9c5.4 29 10.8 58 16.2 87 .2 1.1.6 2.2 1.1 4.1.8-1.8 1.3-2.8 1.7-3.8C127.5 77.1 140.9 43.1 154.4 9c.6-1.4 1-2.6 3-2.6 13.2.1 26.5.1 39.7.1C197.4 6.4 197.6 6.6 198.4 6.8z"
					fill="currentcolor"
				/>
			</g>
		</g>
	</svg>
);

export default SvgIconVisa;
