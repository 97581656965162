import React from "react";

const IconChild = props => (
	<svg viewBox="10 0 15 33.6" {...props}>
		<g fill="currentColor">
			<path d="M24.19 23.17v.28l0 .23a2.22 2.22 0 0 1-2.57 1.72l-.33-.07v.2q0 2.91 0 5.83a2 2 0 0 1-1.54 2.05l-.39.08h-.71l-.09 0a1.66 1.66 0 0 1-.94-.4.1.1 0 0 0-.17 0 1.7 1.7 0 0 1-.81.39l-.22 0h-.71l-.12 0a2 2 0 0 1-1.68-1.37 2.4 2.4 0 0 1-.11-.77c0-1.93 0-3.87 0-5.8v-.19l-.15 0a2.09 2.09 0 0 1-1.07 0 2.13 2.13 0 0 1-1.68-1.69l-.05-.23v-.28l.28-1c.35-1.33.71-2.66 1.05-4 .1-.41.13-.83.25-1.24a2.84 2.84 0 0 1 1.46-1.85 2.26 2.26 0 0 1 1-.24c1.75 0 3.5 0 5.25 0a2.46 2.46 0 0 1 2.19 1.37 4.36 4.36 0 0 1 .44 1.37c.16.81.36 1.62.57 2.43s.51 1.83.77 2.74C24.13 22.87 24.16 23 24.19 23.17zm-1.85 1.22a1.13 1.13 0 0 0 .79-1.46c-.29-.93-.55-1.87-.79-2.81s-.4-1.63-.56-2.45a2.89 2.89 0 0 0-.51-1.28 1.43 1.43 0 0 0-1.16-.6H14.89a1.37 1.37 0 0 0-1 .42 2.12 2.12 0 0 0-.58 1.05c-.14.62-.23 1.26-.39 1.87-.33 1.28-.69 2.55-1 3.82a1.13 1.13 0 0 0 0 .63 1.18 1.18 0 0 0 .94.85A1 1 0 0 0 14 23.7c.11-.45.21-.89.31-1.34l.4-1.7h0V30.93c0 .17 0 .33 0 .5a1 1 0 0 0 .82 1 2.74 2.74 0 0 0 .78 0 .76.76 0 0 0 .71-.66 2.78 2.78 0 0 0 0-.55q0-3.07 0-6.15s0-.09 0-.14 0-.11.11-.11h.48c.21 0 .21 0 .21.21q0 3 0 6.09c0 .16 0 .32 0 .48a.83.83 0 0 0 .63.81 3.47 3.47 0 0 0 .76 0 1.09 1.09 0 0 0 .45-.15 1.05 1.05 0 0 0 .5-1q0-5.26 0-10.53v-.16a.25.25 0 0 1 0 .11c.23 1 .45 2 .69 2.93a.89.89 0 0 0 .74.73A1.55 1.55 0 0 0 22.33 24.39zM20.7 10.79a3.23 3.23 0 1 1-3.91-2.7l.54-.1h.34l.5.09A3.2 3.2 0 0 1 20.7 10.79zm-.95.45a2.25 2.25 0 1 0-2.26 2.23A2.27 2.27 0 0 0 19.75 11.25z" />
		</g>
	</svg>
);

export default IconChild;
