import React from "react";

const IconRight = props => (
	<svg viewBox="0 0 512 512" {...props}>
		<path
			fill="currentColor"
			d="M387.1,245.8L145.5,4.3c-5.7-5.7-14.8-5.7-20.5,0s-5.7,14.8,0,20.5L356.3,256L125,487.2c-5.7,5.7-5.7,14.8,0,20.5c2.8,2.8,6.5,4.3,10.2,4.3s7.4-1.4,10.2-4.3L387,266.2C392.6,260.6,392.6,251.4,387.1,245.8z"
		/>
	</svg>
);

export default IconRight;
