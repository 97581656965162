import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage, injectIntl, intlShape } from "react-intl";
import WhatsAppShareButton from "app/pages/.shared/social/whatapps/WhatsAppShareButton";
import MessengerShareLink from "app/pages/.shared/social/facebook/MessengerShareLink";
import CopyToClipboardButton from "app/pages/.shared/CopyToClipboardButton/CopyToClipboardButton";
import MailToButton from "app/pages/.shared/MailTo/MailToButton";
import { sendTagOnSocialShare } from "app/utils/analytics";
import { resolutionPropType } from "app/utils/propTypes";
import { RESOLUTION } from "app/constants";
import { registerStyle } from "app/utils/CriticalCSS";
import style from "./social-bar.scss";
import { stringify } from "query-string";

class SocialBar extends React.PureComponent {
	constructor() {
		super();
		this.state = {
			linkCopied: false,
		};

		this.onCopySuccess = this.onCopySuccess.bind(this);
		this.onCopyReset = this.onCopyReset.bind(this);
		this.onMailShare = this.onMailShare.bind(this);
		this.onWhatsAppShare = this.onWhatsAppShare.bind(this);
		this.onFacebookShare = this.onFacebookShare.bind(this);
		this.onMessengerShare = this.onMessengerShare.bind(this);
		this.onTwitterShare = this.onTwitterShare.bind(this);
	}

	onCopySuccess() {
		this.setState(
			{
				linkCopied: true,
			},
			() => {
				sendTagOnSocialShare("clipboard");
			}
		);
	}

	onMailShare() {
		sendTagOnSocialShare("email");
	}

	onWhatsAppShare() {
		sendTagOnSocialShare("whatsapp");
	}

	onFacebookShare() {
		sendTagOnSocialShare("facebook");
	}

	onMessengerShare() {
		sendTagOnSocialShare("messenger");
	}

	onTwitterShare() {
		sendTagOnSocialShare("twitter");
	}

	onCopyReset() {
		this.setState({
			linkCopied: false,
		});
	}

	render() {
		const { urlToShare, resolution, intl, partnerData = {} } = this.props;

		const partnerDataQuery = stringify(partnerData);

		const emailSubject = intl.formatMessage({ id: "share.email.subject" });
		const emailBody = intl.formatMessage(
			{ id: "share.email.body" },
			{ url: `${urlToShare}?source=share_email%26${partnerDataQuery}` }
		);

		return (
			<div className="social-bar">
				<div className="social-bar__title">
					<FormattedMessage id="share.title.label" />
				</div>
				<div className="social-bar__body">
					{(resolution === RESOLUTION.SMALL || resolution === RESOLUTION.MEDIUM) && (
						<div className="social-bar__item">
							<WhatsAppShareButton
								onClick={this.onWhatsAppShare}
								label={<FormattedMessage id="share.whatsapp.button.label" />}
								text={`${urlToShare}?source=share_whatsapp%26${partnerDataQuery}`}
							/>
						</div>
					)}
					<div className="social-bar__item">
						<MessengerShareLink
							resolution={resolution}
							onClick={this.onMessengerShare}
							label={<FormattedMessage id="share.messenger.button.label" />}
							link={`${urlToShare}?source=share_messenger&${partnerDataQuery}`}
						/>
					</div>
					<div className="social-bar__item">
						<MailToButton
							subject={emailSubject}
							onClick={this.onMailShare}
							label={<FormattedMessage id="share.email.button.label" />}
							body={emailBody}
						/>
					</div>
					<div className="social-bar__item">
						<CopyToClipboardButton
							onCopySuccess={this.onCopySuccess}
							onReset={this.onCopyReset}
							label={<FormattedMessage id="share.clipboard.button.label" />}
							text={`${urlToShare}?source=share_copylink&${partnerDataQuery}`}
						/>
					</div>
				</div>
			</div>
		);
	}
}

SocialBar.propTypes = {
	intl: intlShape,
	resolution: resolutionPropType,
	urlToShare: PropTypes.string.isRequired,
	partnerData: PropTypes.object,
};

export default injectIntl(registerStyle(style)(SocialBar));
