import React from "react";

const IconFriends = props => (
	<svg id="Calque_1" x={0} y={0} viewBox="0 0 20 20" xmlSpace="preserve" {...props}>
		<style>{".st0{fill:currentColor}"}</style>
		<path
			d="M8.8 7.6l.9.9v.8c0 .4.3.7.7.7l0 0c.4 0 .7-.3.7-.7V8.2c0-.2-.1-.4-.2-.5l0 0L9.8 6.6 9.8 6.5 8.9 5.6c-1-1-2.7-1-3.8 0l0 0c-1 1-1 2.7 0 3.8l1 1L8.8 7.6zM10.8 13.5c-.2-.2-.6-.2-.8 0v0c-.2.2-.2.6 0 .8l.6.6c.2.2.6.2.8 0l0 0c.2-.2.2-.6 0-.8L10.8 13.5zM11.8 12.5c-.2-.2-.6-.2-.8 0s-.2.6 0 .8l.6.6c.2.2.6.2.8 0 .2-.2.2-.6 0-.8L11.8 12.5zM12.8 11.5c-.2-.2-.6-.2-.8 0h0c-.2.2-.2.6 0 .8l.6.6c.2.2.6.2.8 0v0c.2-.2.2-.6 0-.8L12.8 11.5zM13.8 10.6c-.2-.2-.6-.2-.8 0-.2.2-.2.6 0 .8l.6.6c.2.2.6.2.8 0 .2-.2.2-.6 0-.8L13.8 10.6z"
			className="st0"
		/>
		<path
			d="M14.9,5.6L14.9,5.6c-1-1-2.7-1-3.8,0l-1,1l1,1c0,0,0,0,0,0c0.1,0.2,0.2,0.4,0.2,0.6l0,1.1 c0,0.2-0.1,0.5-0.3,0.6c-0.2,0.2-0.4,0.3-0.6,0.3c-0.5,0-0.9-0.4-0.9-0.9V8.8V8.7V8.6L8.9,7.9l0,0l-3.3,3.3c-0.2,0.2-0.2,0.6,0,0.8 v0c0.2,0.2,0.6,0.2,0.8,0l2.6-2.6l0.2,0.2l-2.6,2.6c-0.2,0.2-0.2,0.6,0,0.8h0c0.2,0.2,0.6,0.2,0.8,0l2.6-2.6l0.2,0.2l0,0l-2.6,2.6 c-0.2,0.2-0.2,0.6,0,0.8l0,0c0.2,0.2,0.6,0.2,0.8,0l2.6-2.6l0.2,0.2l-2.6,2.6c-0.2,0.2-0.2,0.6,0,0.8l0,0c0.2,0.2,0.6,0.2,0.8,0 l0.5-0.5c-0.3-0.3-0.3-0.8,0-1.1c0.2-0.2,0.6-0.3,0.8-0.2c-0.1-0.3-0.1-0.6,0.2-0.8c0.2-0.2,0.6-0.3,0.8-0.2 c-0.1-0.3-0.1-0.6,0.2-0.8c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0 c0,0,0,0,0,0c0,0,0,0,0,0c0.2-0.1,0.4-0.1,0.6,0c-0.1-0.3-0.1-0.6,0.2-0.8c0.3-0.3,0.8-0.3,1.1,0l1-1C16,8.4,16,6.7,14.9,5.6z"
			className="st0"
		/>
		<g>
			<circle
				cx={10}
				cy={10}
				r={9}
				fill="none"
				stroke="currentColor"
				strokeDasharray="0,.9918"
				strokeLinecap="round"
				strokeLinejoin="bevel"
				strokeWidth={0.4}
			/>
		</g>
	</svg>
);

export default IconFriends;
