import React from "react";

const IconBreadcrumCheck = props => (
	<svg viewBox="0 0 14.042 10.157" {...props}>
		<path
			fill="none"
			stroke="currentColor"
			strokeWidth={2}
			d="M0 0l8.035 8.121-4.51 4.51"
			data-name="Trac\xE9 953"
			transform="rotate(90 6.313 7.025)"
		/>
	</svg>
);

export default IconBreadcrumCheck;
