import React from "react";

const IconTickboxTick = props => (
	<svg viewBox="0 0 22 21.9" {...props}>
		<path
			fill="currentColor"
			d="M18.9,23H4.2A3.65,3.65,0,0,1,.55,19.3V4.7A3.65,3.65,0,0,1,4.2,1H14.3V3H4.2A1.73,1.73,0,0,0,2.45,4.7V19.3A1.73,1.73,0,0,0,4.2,21H18.9a1.73,1.73,0,0,0,1.75-1.75V8.4h1.9V19.3A3.65,3.65,0,0,1,18.9,23Z"
			transform="translate(-.55 -1.05)"
		/>
		<g fill="currentColor">
			<path
				d="M10.8,17.4h0a1.48,1.48,0,0,1-2.2-.2L4.9,12.9a1.48,1.48,0,0,1,.2-2.2h0a1.48,1.48,0,0,1,2.2.2L11,15.2A1.5,1.5,0,0,1,10.8,17.4Z"
				transform="translate(-.55 -1.05)"
			/>
			<path
				d="M20.2,1.6C17.6,4.2,12,11.7,9.8,13.9l.1,2.3,1,1.1s7.7-11,10.1-15C21.3,1.7,20.7,1.2,20.2,1.6Z"
				transform="translate(-.55 -1.05)"
			/>
		</g>
	</svg>
);

export default IconTickboxTick;
