import React from "react";

const IconAdult = props => (
	<svg viewBox="10 0 15 33.6" {...props}>
		<path
			fill="currentColor"
			d="M20.47,9.34a1.57,1.57,0,0,1,1.19.57,2.63,2.63,0,0,1,.61,1.37c.2,1.6,1.6,6.26,1.6,6.26A1.25,1.25,0,0,1,23,19l-.17.05a1.17,1.17,0,0,1-.34.05,1.07,1.07,0,0,1-1-.84l-.87-3.72V31.39a1.17,1.17,0,0,1-1.18,1.16h-.48c-.65,0-.95-.52-.95-1.16V21h-.71V31.39a1.17,1.17,0,0,1-1.18,1.16h-.49a1.17,1.17,0,0,1-1.18-1.16V14.56l-.87,3.72a1.07,1.07,0,0,1-1,.84,1.17,1.17,0,0,1-.34-.05L12,19a1.25,1.25,0,0,1-.84-1.48s1.39-4.66,1.6-6.26a2.63,2.63,0,0,1,.61-1.37,1.57,1.57,0,0,1,1.19-.57h5.94m0-1.08H14.53a2.66,2.66,0,0,0-2,.94,3.68,3.68,0,0,0-.87,1.94,62.76,62.76,0,0,1-1.56,6.09A2.34,2.34,0,0,0,11.66,20l.17.05a2.25,2.25,0,0,0,.66.1,2.16,2.16,0,0,0,.84-.17V31.39a2.25,2.25,0,0,0,2.26,2.24h.49a2.26,2.26,0,0,0,1.47-.54,1.93,1.93,0,0,0,1.37.54h.48a2.25,2.25,0,0,0,2.26-2.24V20a2.16,2.16,0,0,0,.84.17,2.25,2.25,0,0,0,.66-.1l.17-.05a2.34,2.34,0,0,0,1.57-2.77,63.08,63.08,0,0,1-1.57-6.13,3.69,3.69,0,0,0-.87-1.94,2.65,2.65,0,0,0-2-.94Z"
		/>
		<path
			fill="currentColor"
			d="M17.5,1.86A2.52,2.52,0,1,1,15,4.38,2.52,2.52,0,0,1,17.5,1.86m0-1.08a3.59,3.59,0,1,0,3.59,3.59A3.6,3.6,0,0,0,17.5.78Z"
		/>
	</svg>
);

export default IconAdult;
