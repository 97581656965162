import React from "react";

const SvgIconTwitterWhite = props => (
	<svg viewBox="0 0 298 242.19" {...props}>
		<path
			fill="currentColor"
			d="M94.72,243.19c112.46,0,174-93.17,174-174q0-4-.17-7.9A124.32,124.32,0,0,0,299,29.67a121.91,121.91,0,0,1-35.11,9.63A61.35,61.35,0,0,0,290.78,5.48,122.61,122.61,0,0,1,252,20.32,61.19,61.19,0,0,0,147.76,76.07a173.59,173.59,0,0,1-126-63.88A61.19,61.19,0,0,0,40.67,93.82,60.69,60.69,0,0,1,13,86.17c0,.26,0,.51,0,.78A61.16,61.16,0,0,0,62,146.88a61,61,0,0,1-27.61,1A61.2,61.2,0,0,0,91.52,190.4a122.65,122.65,0,0,1-75.94,26.17A124.19,124.19,0,0,1,1,215.72a173.07,173.07,0,0,0,93.72,27.46"
			transform="translate(-1 -1)"
		/>
	</svg>
);

export default SvgIconTwitterWhite;
