import React from "react";

const SvgIconThumb = props => (
	<svg viewBox="0 0 31.244 30.5" {...props}>
		<path
			fill="currentColor"
			stroke="currentColor"
			strokeWidth={0.5}
			d="M29.822 20.388a3.826 3.826 0 0 0 .922-2.476 2.965 2.965 0 0 0-.922-2.184 3.109 3.109 0 0 0-.769-3.786c-1.076-.874-2.767-1.311-5.38-1.165a36.506 36.506 0 0 0-3.228.437c-.307 0-.615.146-1.076.146a10.225 10.225 0 0 1 .769-3.641c.922-2.621.922-4.66-.154-6.117A4.405 4.405 0 0 0 16.6 0a1.425 1.425 0 0 0-1.23.583 4.058 4.058 0 0 0-.459 2.917c-.922 2.33-3.382 7.718-5.38 9.175l-.154.146a4.436 4.436 0 0 0-1.23 1.893 2.573 2.573 0 0 0-1.383-.437h-4A2.686 2.686 0 0 0 0 16.893v10.194a2.686 2.686 0 0 0 2.767 2.621h4a2.7 2.7 0 0 0 1.533-.436l1.537.146a65.913 65.913 0 0 0 8.762.437c.769 0 1.537.146 2.152.146a18.213 18.213 0 0 0 3.074-.291c2-.437 3.382-1.165 4.15-2.476a5.052 5.052 0 0 0 .461-2.33 3.854 3.854 0 0 0 1.537-3.2 1.742 1.742 0 0 0-.151-1.316zM2.767 27.961a.967.967 0 0 1-.922-.874V16.893a.967.967 0 0 1 .922-.874h4a.862.862 0 0 1 .922.874v10.194a.967.967 0 0 1-.922.874zm25.364-8.3a.861.861 0 0 0-.154 1.019 1.943 1.943 0 0 1 .307 1.019 2.384 2.384 0 0 1-1.23 2.184.872.872 0 0 0-.307 1.019 2.025 2.025 0 0 1-.154 1.6 4.173 4.173 0 0 1-2.921 1.6 16.322 16.322 0 0 1-4.765.146h-.154a58.7 58.7 0 0 1-8.608-.437l-.615-.146V16.893c0-.291 0-.583-.154-.728a6.31 6.31 0 0 1 1.23-2.184c2.921-2.184 5.841-9.757 6-10.049V3.5a2.785 2.785 0 0 1 .154-1.748 2.622 2.622 0 0 1 1.845.874c.615.874.615 2.476-.154 4.515-1.076 3.2-1.23 4.806-.307 5.68a1.908 1.908 0 0 0 1.537.146 10.218 10.218 0 0 0 1.076-.291h.154c2-.437 5.688-.728 6.917.437a1.686 1.686 0 0 1 .154 2.33.68.68 0 0 0 .154 1.019 2.039 2.039 0 0 1 .769 1.456 1.837 1.837 0 0 1-.774 1.742z"
			data-name="Trac\xE9 929"
			transform="translate(.25 .25)"
		/>
	</svg>
);

export default SvgIconThumb;
