import React from "react";

const SvgIconBagageSoute = props => (
	<svg viewBox="0 0 23.557 23.43" {...props}>
		<path
			fill="currentColor"
			d="M20.7 5.143h-3.937V2.222A2.25 2.25 0 0 0 14.541 0H9.016a2.25 2.25 0 0 0-2.222 2.222v2.921h-4A2.819 2.819 0 0 0 0 7.937v11.112a2.778 2.778 0 0 0 2.794 2.794h.889v.508a1.07 1.07 0 0 0 1.079 1.079 1.033 1.033 0 0 0 1.079-1.079v-.508h11.874v.508a1.07 1.07 0 0 0 1.079 1.079 1.033 1.033 0 0 0 1.079-1.079v-.508h.889a2.778 2.778 0 0 0 2.794-2.794V7.937A2.918 2.918 0 0 0 20.7 5.143zM8.064 2.222a.977.977 0 0 1 .952-.952h5.524a.977.977 0 0 1 .952.952v2.921h-1.205a1.07 1.07 0 0 0-1.079-1.079h-2.922a1.07 1.07 0 0 0-1.079 1.079H8V2.222z"
		/>
	</svg>
);

export default SvgIconBagageSoute;
