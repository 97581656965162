import React from "react";
import className from "classnames";
import PopoverButton from "app/pages/.shared/Popover/PopoverButton";
import SocialBarContainer from "app/pages/.shared/social/SocialBarContainer";
import "./socialbar-popover.scss";
import PropTypes from "prop-types";

class SocialBarPopover extends React.PureComponent {
	constructor(props) {
		super(props);

		if (props.createRef) {
			this.socialBarPopoverRef = React.createRef();
		}
	}

	render() {
		const { buttonLabel, popOverPosition = "below", urlToShare, createRef } = this.props;

		const classname = className({
			"socialbar-popover__popover": true,
		});

		return (
			<div className="socialbar-popover" ref={this.socialBarPopoverRef}>
				<PopoverButton
					popOverClassName={classname}
					buttonLabel={buttonLabel}
					popOverPosition={popOverPosition}
					popOverContent={<SocialBarContainer urlToShare={urlToShare} />}
					closeOnBodyClick={false}
					appendTarget={createRef && this.socialBarPopoverRef.current}
					tipSize={10}
				/>
			</div>
		);
	}
}

SocialBarPopover.propTypes = {
	popOverPosition: PropTypes.string,
	createRef: PropTypes.string,
	buttonLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	urlToShare: PropTypes.string.isRequired,
};

export default SocialBarPopover;
