import React from "react";

const SvgIconGoToTopArrow = props => (
	<svg viewBox="0 0 60 60" {...props}>
		<g>
			<image
				style={{
					mixBlendMode: "multiply",
				}}
				width={60}
				height={60}
				opacity={0.07}
				xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAA8CAYAAAA6/NlyAAAACXBIWXMAAAsSAAALEgHS3X78AAAFWklEQVRoQ+WbXU8bRxiFn9lgCCGkJJS2JBRESqOmVZEgErnuL+91kEKlVm2qlAZBIW5onSbQ8GETTy/OjD273vV3YNc90soxWjn7+LzvzOzssbHWMmwZY4z/Z9sTs2UB7Ae4ODOsz3SQ/igB4+61V2gL1ICqe7WIfSgXOjCwA42AMeC6O2aAT4Bb7u/dQlvgAjgCDoE3wJk7LoD6oOB9AwegJWAKuAPMA3PAXWAF+BSYyPqMDJ0Dr4Bt4CXwF1AGXgPvkOt9g/cFbIzxoDeBWWAR+BL4ClgAPkawt9x5vaiGHH4F/A3sA78CvwF7QAX4F6hZa+tZH5KlnoADVydRyS4DD4BvEfDnqJyvI2cH6eFzVMpvgD8Q8E/Ac2AHlfyptfZ9xuekqmvgwNVpVLqrwAbwEFhCTk/RhPRHP7LBUUOlXAF2gWfAE+AH5P4JPZT4WKcTAIwx14i7+g2CXUXwNxFoRP+QocIvKzlOfAZ85P42hvr72BjTVYl3BA5gF4B14DECXkZfwCTDA02TAa7RBJ9A111C4D/jStwY07HE2wK7MvawG8B3wCPk6jRNVy9DHnwKjRUTaGD8ApX4FrDfCToT2A1QJeTiOoJ9jOBv8GFdbacI/f/30CwQzvcg6JOs8m7ncIRcvI9AHyHYKS7P1SxFaCV3m2aJgxYnVeDAGFNNG8hSgV3f3kCl+7U75mk6mweFJb5AfIV2BPwDtJR2y8Un+tYPUveR23mBDeVLPGnOpGOJKeZwom/XUN+uuff9LCIuS2H7baA5+x0ppZ0s6QjNqcvI2XXig1ReFRq1ioD/BN6ilVqjtBsQgbuzaLn4kPz1bTv5VpxH1/4AsZSC+/MYSIQGgCW0Nl5CbhcB1stXaMgQm1UiaLg7hpZuK+6YJd99m6awSj3HHWDMu+zJDbrDmUe3eIvkY77tR75SFxHLPGJLBZ5DK5gZiueul3d5BrHMEQIHg9UM2qmInVBQhQbexRlogol5HA3p4bZM0YH9zcUKYhuH5g1ACS3Aw22ZogOnMoU9PMjWah6VypQchUcBNKkYUwg8irAtCnt4lMo5UxFaYU2jkWya3p4UFE5+52AW7RPNuvcjqyIuHQdShPaAKmh3v+Lej6witBd0jPaCjt37rnbxi6iI9OexI6uwh0ca1Cs5aI0idIzJA49iWacyhT3sH0IfUXzoTCbvcBWN0tvuhHOKDxxGJw5x023kNqlraP/WZyrOKD7wGWJ5idhq1lob9rA/4cCfQDGhQwMPSBiYBC6jAMkeelTR8Yl6DlVH176HWMokgV1ZX6Bo0LY7KhTPZe9uhSbHa+DCP18K52H/zeyitMwuigcVyeU6uuaQIVapDeBg8KqgaNAzVA6nFAO6jhI9ZXTtz3FV2u7pof+GdoBNmlGke+g+Oa8bAx52H2U9niCGlgqNAVtrrTGmhuatHxGw3+a8jZ64502+Mg9Rdut793pIwl1I2QBwYZBTVBq/uKOMC4Alz8+B6ui29gVBmgel9FquNyvUkvwQn5K56gRPUmHfxszJii6lAidKeys474KryWglZYn37VNkzAtkVGYlZsaWrLV1Y8wp+kBopmTCFN5VpAPqqGePkZtPUd9ukdG3odom8ay17wPoKlqM/47yH2tcbol7V08R2A6KHXbs21BtgaEBfYLWpT4H9RZ9y77Ehx0uDeVBa2iaKaMZZJMgZ0mXUeKOwNAo7yoKe50j0BoCv4r48CaCLqPS7ipJC3Sfl/YyrVHiKw2I00NWGvoABv4/PwEI5aISHny0f+QRKgAfY5R/xpOUA/fHII9ffQ/n84daaQqifr3Cello3LIOVf8B11paYg2uAmIAAAAASUVORK5CYII="
			/>
			<circle cx={30} cy={28} r={26.75} fill="#fff" />
			<path
				fill="#d9d9d9"
				d="M30,1.5A26.5,26.5,0,1,1,3.5,28,26.53,26.53,0,0,1,30,1.5M30,1A27,27,0,1,0,57,28,27,27,0,0,0,30,1Z"
			/>
			<path
				fill="currentColor"
				stroke="currentColor"
				strokeMiterlimit={10}
				strokeWidth={0.25}
				d="M29.48,20.34,17.22,32.61a.74.74,0,0,0,1,1L30,21.9,41.74,33.65a.73.73,0,0,0,1,0,.74.74,0,0,0,.22-.52.7.7,0,0,0-.22-.51L30.52,20.35A.74.74,0,0,0,29.48,20.34Z"
			/>
		</g>
	</svg>
);

export default SvgIconGoToTopArrow;
