import React from "react";

const IconPrint = props => (
	<svg viewBox="0 0 612 612" {...props}>
		<path
			fill="currentColor"
			d="M512.1,551.8c0-14.4,0-28.8,0-43.2c0-29.5,0-59,0-88.4c25,0,50,0,74.9,0c13.8,0,25-11.2,25-25c0-81.6,0-163.2,0-244.8c0-13.8-11.2-25-25-25c-25,0-50,0-74.9,0c0-22.5,0-45,0-67.4c0-13.8,0-16.1,0-25c-120.7,0-266.4,0-387.2,0c-7.3,0-19.4,0-25.1,0c0,5.2,0.1,18.5,0.1,25c0,22.5,0,45,0,67.4c-25,0-50,0-74.9,0c-13.8,0-25,11.2-25,25C0,232,0,313.6,0,395.2c0,13.8,11.2,25,25,25c25,0,50,0,74.9,0c0,29.5,0,59,0,88.5c0,14.4,0,28.8,0,43.2L99.8,579l29.2-0.1c59,0,118,0,177,0s118,0,177,0l29.1,0.2V551.8z M62.5,206.4c-8.2,0-14.8-6.6-14.8-14.8s6.6-14.8,14.8-14.8s14.8,6.6,14.8,14.8S70.6,206.4,62.5,206.4zM487.1,553.8H124.9V356.5h362.2V553.8L487.1,553.8z M487.1,125.4c-120.7,0-241.5,0-362.2,0c0-14.2,0-28.3,0-42.5c0-13.8,0-16.6,0-25c104.1,0,258.2,0,362.2,0c0,8.2,0,11.2,0,25C487.1,97.1,487.1,111.3,487.1,125.4z"
		/>
	</svg>
);

export default IconPrint;
