import React from "react";

const SvgIconLinkWhite = props => (
	<svg viewBox="0 0 40.65 40.65" {...props}>
		<path
			fill="currentColor"
			d="M158.32,356.93l-2.79,2.79a5.13,5.13,0,0,1-7.25-7.25l8.48-8.48a5.13,5.13,0,0,1,7.25,0l0,0a1.71,1.71,0,0,0,2.41,0l1.17-1.16a1.71,1.71,0,0,0,0-2.41,10.23,10.23,0,0,0-14.47,0l-8.48,8.48a10.23,10.23,0,0,0,14.47,14.47L164.5,358A13.65,13.65,0,0,1,158.32,356.93Z"
			transform="translate(-141.67 -325.67)"
		/>
		<path
			fill="currentColor"
			d="M179.33,328.67a10.23,10.23,0,0,0-14.47,0L159.5,334a13.66,13.66,0,0,1,6.17,1l2.8-2.8a5.13,5.13,0,1,1,7.25,7.25L167.24,348a5.14,5.14,0,0,1-7.25,0,1.7,1.7,0,0,0-2.41,0l-1.16,1.17a1.71,1.71,0,0,0,0,2.4l0,0a10.23,10.23,0,0,0,14.47,0l8.48-8.48A10.23,10.23,0,0,0,179.33,328.67Z"
			transform="translate(-141.67 -325.67)"
		/>
	</svg>
);

export default SvgIconLinkWhite;
