import React from "react";

const SvgIconBagageCabine = props => (
	<svg viewBox="0 0 13.572 24" {...props}>
		<path
			fill="currentColor"
			d="M12.052 6.026H9.9V1.153A1.163 1.163 0 0 0 8.751 0h-3.93a1.163 1.163 0 0 0-1.153 1.153v4.821H1.52A1.521 1.521 0 0 0 0 7.493v14.2a1.521 1.521 0 0 0 1.52 1.52h1.1a1.024 1.024 0 0 0 1.991 0h4.3a1.024 1.024 0 0 0 1.991 0h1.153a1.521 1.521 0 0 0 1.52-1.52V7.546a1.521 1.521 0 0 0-1.523-1.52zM5.24 2.83a.47.47 0 0 1 .472-.472h2.2a.47.47 0 0 1 .472.472v3.2H5.24v-3.2zm6.236 7.86H2.1a.786.786 0 1 1 0-1.572h9.432a.806.806 0 0 1 .786.786.816.816 0 0 1-.842.786z"
			data-name="Trac\xE9 912"
		/>
	</svg>
);

export default SvgIconBagageCabine;
