import React from "react";

const SvgIconWhatsappWhite = props => (
	<svg viewBox="0 0 1800.56 1808.65" {...props}>
		<path
			fill="currentColor"
			d="M1912.36,593.86C1743.1,424.46,1518,331.12,1278.17,331,784,331,381.85,733,381.65,1227.2a894.43,894.43,0,0,0,119.67,448.05L374.14,2139.68,849.39,2015a896.13,896.13,0,0,0,428.42,109.09h.37c494.1,0,896.31-402.07,896.51-896.23.1-239.47-93.07-464.65-262.33-634ZM1278.18,1972.77h-.31a744.17,744.17,0,0,1-379.26-103.83l-27.21-16.14-282,74,75.28-274.88-17.72-28.18A743,743,0,0,1,533,1227.26c.16-410.72,334.44-744.86,745.46-744.86,199,.08,386.12,77.66,526.8,218.46s218.12,328,218,527c-.16,410.75-334.43,744.92-745.14,744.92Zm408.73-557.9c-22.4-11.21-132.53-65.39-153.07-72.86s-35.46-11.21-50.4,11.21-57.86,72.86-70.93,87.8-26.13,16.82-48.53,5.61-94.58-34.86-180.14-111.15c-66.59-59.37-111.55-132.71-124.62-155.13s-1.39-34.54,9.82-45.7c10.08-10,22.4-26.15,33.6-39.23s14.93-22.42,22.4-37.36,3.73-28-1.87-39.23-50.4-121.43-69.07-166.27c-18.18-43.67-36.65-37.76-50.4-38.44-13.05-.65-28-.79-42.93-.79s-39.2,5.6-59.73,28-78.4,76.6-78.4,186.82,80.27,216.71,91.47,231.65,158,241.14,382.67,338.14c53.45,23.07,95.17,36.85,127.71,47.17,53.66,17,102.5,14.64,141.1,8.87,43-6.43,132.53-54.17,151.2-106.48s18.67-97.15,13.07-106.49-20.53-14.95-42.93-26.16Z"
			transform="translate(-374.14 -331.03)"
		/>
	</svg>
);

export default SvgIconWhatsappWhite;
