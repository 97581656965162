import React from "react";

const SvgIconSuccessLinkWhite = props => (
	<svg id="Calque_1" x={0} y={0} viewBox="0 0 48.9 48.9" xmlSpace="preserve" {...props}>
		<style>{".st0{fill:currentColor}"}</style>
		<path
			d="M21.7,39L21.7,39c-1,1.1-2.7,1.1-3.8,0l-0.2-0.2l-7.1-7.5c-1.1-1-1.1-2.7-0.1-3.8l0.2-0.2l0,0c1-1.1,2.7-1.1,3.8,0l0.2,0.2l7.1,7.5c1.1,1.1,1.1,2.8,0,3.8c0,0,0,0,0,0L21.7,39z"
			className="st0"
		/>
		<path
			d="M37.3,9.5c-4.5,5-13.9,19.1-17.7,23.2l0.4,4.1l1.9,1.9c0,0,13-20.6,17-28.1C39.3,9.6,38.2,8.7,37.3,9.5z"
			className="st0"
		/>
		<g>
			<path
				fill="none"
				stroke="currentColor"
				strokeMiterlimit={10}
				strokeWidth={2.858}
				d="M44.4,13c6.3,11,2.5,25.1-8.5,31.4s-25.1,2.5-31.4-8.5S2,10.8,13,4.5c6.2-3.6,13.7-4,20.3-1.3"
			/>
		</g>
	</svg>
);

export default SvgIconSuccessLinkWhite;
