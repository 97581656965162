import React from "react";

const IconArrowUp = props => (
	<svg viewBox="0 0 511.97 270.49" {...props}>
		<path
			fill="currentColor"
			d="M245.76,124.88,4.26,366.48A14.5,14.5,0,0,0,24.76,387L256,155.68,487.16,387a14.55,14.55,0,0,0,24.8-10.2,14.08,14.08,0,0,0-4.3-10.2L266.16,125A14.58,14.58,0,0,0,245.76,124.88Z"
			transform="translate(.02 -120.77)"
		/>
	</svg>
);

export default IconArrowUp;
