import React from "react";

const IconArrowDown = props => (
	<svg viewBox="0 0 511.97 270.49" {...props}>
		<path
			fill="currentColor"
			d="M266.18,387.14l241.5-241.6a14.5,14.5,0,1,0-20.5-20.5L256,356.34,24.78,125A14.55,14.55,0,0,0,0,135.24a14.08,14.08,0,0,0,4.3,10.2L245.78,387A14.58,14.58,0,0,0,266.18,387.14Z"
			transform="translate(.02 -120.77)"
		/>
	</svg>
);

export default IconArrowDown;
