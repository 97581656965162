import React from "react";

const IconLogoSeto = props => (
	<svg id="Calque_1" x={0} y={0} viewBox="0 0 78.5 78.5" xmlSpace="preserve" {...props}>
		<style>{".st2{fill:white}"}</style>
		<path
			fill="#b7b7b7"
			d="M78.5,39.3L78.5,39.3H0v0.1l0,0l7.1,5.9h67c-5.7,15.6-20.6,25.9-37.2,26c-9.9,0-19.5-3.8-26.8-10.6L3.2,55 c8.7,19.9,31.8,29,51.7,20.3C69.2,69,78.5,54.9,78.5,39.3z"
		/>
		<path
			fill="currentColor"
			d="M71.4,33.4h-67c5.7-15.6,20.6-26,37.2-26c9.9,0,19.5,3.7,26.8,10.4l6.8,5.8C66.6,3.7,43.5-5.4,23.6,3.3 C9.3,9.5,0,23.6,0,39.3h78.5L71.4,33.4z"
		/>
		<path
			d="M22.2 37.5L22 37.4c-.3-1.5-1.6-2.5-3.2-2.5-1.2 0-2.2.5-2.2 1.6 0 .8.4 1.1 2.9 1.5s4.1.9 4.1 2.7c0 2.3-2.9 3.2-5.1 3.2-1.7 0-3.4-.3-5.1-.9l.9-2.6.2.1c.4 1.7 1.9 3 3.7 2.9 1.3 0 2.7-.4 2.7-1.8 0-1.1-.7-1.3-3.5-1.8-2-.3-3.5-.8-3.5-2.7 0-2.2 3-2.8 5-2.8 1.4 0 2.8.2 4.1.6L22.2 37.5zM26.5 36.5c0-.9-.2-1.4-1-1.6v-.2h1.2 1.1 4.3 2.7l.8 2.5h-.3c-1.1-1.2-2.7-2-4.4-2h-1.5v3.5h1c1.4 0 2.8-.2 3.3-1.5H34v3.5h-.3c-.3-1-1.7-1.5-3.4-1.5h-1v2c0 1.4.4 1.9 2 1.9 2-.2 3.8-1.1 5.1-2.7l.3.1-1.2 3.2c-2.2 0-4.5-.1-7.6-.1h-1.3-1.4v-.2c1.3-.1 1.3-1.2 1.3-2.4L26.5 36.5zM38.2 37.2l.8-2.5h10.1l.8 2.5h-.3c-.7-.7-2.1-2-3.3-2-.4-.1-.8.2-.9.7 0 .1 0 .2 0 .2V41c0 1.3.1 2.4 1.3 2.4v.3c-1 0-1.9-.1-2.7-.1s-1.8.1-2.9.1v-.3c1.4-.1 1.5-1.2 1.5-2.4v-4.9c0-.5-.2-.9-.9-.9-1.1 0-2.6 1.3-3.3 2L38.2 37.2zM58 34.5c3.1 0 6.1 1.6 6.1 4.8S61.1 44 58 44s-6.1-1.6-6.1-4.8S54.9 34.5 58 34.5zM58 43.5c1.8 0 2.9-1.3 2.9-4.3s-1.2-4.3-2.9-4.3-2.9 1.3-2.9 4.3S56.2 43.5 58 43.5z"
			className="st2"
		/>
	</svg>
);

export default IconLogoSeto;
