import React from "react";

const SvgIconMyBookings = props => (
	<svg viewBox="1499.5 50 51 49.1" {...props}>
		<path
			stroke="#000"
			fill="#000"
			d="M1544.2 61.1h-9.9v-6.7a3.9 3.9 0 0 0-3.9-3.9h-10.8a3.9 3.9 0 0 0-3.9 3.9v6.7h-9.9a5.8 5.8 0 0 0-5.8 5.8v25.9a5.8 5.8 0 0 0 5.8 5.8h38.4a5.8 5.8 0 0 0 5.8-5.8V66.9a5.8 5.8 0 0 0-5.8-5.8zm-36.3 35.5h-2.1a3.8 3.8 0 0 1-3.8-3.8V66.9a3.8 3.8 0 0 1 3.8-3.8h2.1zm11.7-44.1h10.9c1 0 1.9 1.8 1.9 2.8h-14.7c0-1 .8-2.8 1.9-2.8zm-1.9 4.8h14.6V61h-14.6zm22.3 39.3h-30.1V63.1h30.1zm8-3.8a3.8 3.8 0 0 1-3.8 3.8h-2.2V63.1h2.2a3.8 3.8 0 0 1 3.8 3.8z"
			data-name="Trac\xE9 532"
		/>
	</svg>
);

export default SvgIconMyBookings;
