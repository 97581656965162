import React from "react";

const IconFriendBlue = props => (
	<svg viewBox="0 0 19.5 19" {...props}>
		<style>{".st1{fill:#fff}"}</style>
		<circle cx={9.8} cy={9.5} r={9} fill="currentColor" />
		<path
			d="M12.3 9.2c.5.3.9.6 1.2 1.1a7.8 7.8 0 0 1 1.3 3.8V14.4h-.1l-.2.1c-1.5.7-3 1.1-4.6 1.2h-.2c-.9 0-1.8-.1-2.8-.4-.8-.2-1.5-.5-2.2-.9l.3-1.8c.3-1 .6-1.8 1.1-2.5.3-.4.6-.7 1-.9.6.5 1.4.8 2.2.9h.4a4.1 4.1 0 0 0 2.6-.9m-.1-.8l-.3.1-1 .7a3 3 0 0 1-1.3.3h-.3c-.7-.1-1.3-.4-1.9-.9l-.3-.1-.2.1c-.5.3-1 .7-1.3 1.2A8.4 8.4 0 0 0 4 14.6v.3l.1.2a11.7 11.7 0 0 0 5.5 1.4h.2c1.7 0 3.4-.5 5-1.2l.3-.2.3-.3.1-.1v-.2-.4A8.5 8.5 0 0 0 14 10c-.4-.5-.8-1-1.4-1.3l-.3-.2-.1-.1z"
			className="st1"
		/>
		<path
			d="M12.7 5c.3 1.7-.7 3.3-2.4 3.8-1.7.4-3.5-.7-3.8-2.5a3 3 0 0 1 2.4-3.7l.4-.1h.5l.4.1c1.2.2 2.2 1.2 2.5 2.4zm-.7.7c0-1.3-1-2.4-2.4-2.5a2.5 2.5 0 0 0-2.5 2.4c0 1.3 1 2.4 2.4 2.4 1.4.1 2.5-1 2.5-2.3z"
			className="st1"
		/>
	</svg>
);

export default IconFriendBlue;
