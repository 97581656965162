import React from "react";

const IconInfo = props => (
	<svg data-name="Calque 2" viewBox="0 0 14.29 14.49" {...props}>
		<path
			fill="currentColor"
			d="M7.65,15A7.2,7.2,0,0,1,.5,7.8,7.2,7.2,0,0,1,7.65.55,7.2,7.2,0,0,1,14.8,7.8,7.2,7.2,0,0,1,7.65,15Zm0-13.6A6.31,6.31,0,0,0,1.4,7.8a6.31,6.31,0,0,0,6.25,6.35A6.31,6.31,0,0,0,13.9,7.8,6.31,6.31,0,0,0,7.65,1.45Z"
			transform="translate(-.5 -.55)"
		/>
		<path
			fill="currentColor"
			d="M6.65,5.8v-2h2v2Zm0,6v-5h2v5Z"
			transform="translate(-.5 -.55)"
		/>
	</svg>
);

export default IconInfo;
