import React from "react";

const IconInstagram = props => (
	<svg viewBox="0 0 102 102" {...props}>
		<path
			fill="currentColor"
			d="M51,1C37.4,1,35.7,1.1,30.4,1.3c-5.3,0.2-9,1.1-12.1,2.3c-3.3,1.3-6.1,3-8.9,5.8c-2.8,2.8-4.5,5.6-5.8,8.9 c-1.2,3.2-2.1,6.8-2.3,12.1C1.1,35.7,1,37.4,1,51s0.1,15.3,0.3,20.6c0.2,5.3,1.1,9,2.3,12.1c1.3,3.3,3,6.1,5.8,8.9 c2.8,2.8,5.6,4.5,8.9,5.8c3.2,1.2,6.8,2.1,12.1,2.3c5.3,0.2,7,0.3,20.6,0.3c13.6,0,15.3-0.1,20.6-0.3c5.3-0.2,9-1.1,12.1-2.3 c3.3-1.3,6.1-3,8.9-5.8c2.8-2.8,4.5-5.6,5.8-8.9c1.2-3.2,2.1-6.8,2.3-12.1c0.2-5.3,0.3-7,0.3-20.6s-0.1-15.3-0.3-20.6 c-0.2-5.3-1.1-9-2.3-12.1c-1.3-3.3-3-6.1-5.8-8.9c-2.8-2.8-5.6-4.5-8.9-5.8c-3.2-1.2-6.8-2.1-12.1-2.3C66.3,1.1,64.6,1,51,1L51,1z M46.5,10c1.3,0,2.8,0,4.5,0c13.4,0,14.9,0,20.2,0.3c4.9,0.2,7.5,1,9.3,1.7c2.3,0.9,4,2,5.7,3.7c1.8,1.8,2.8,3.4,3.7,5.8 c0.7,1.8,1.5,4.4,1.7,9.3C91.9,36.1,92,37.6,92,51s-0.1,14.9-0.3,20.2c-0.2,4.9-1,7.5-1.7,9.3c-0.9,2.3-2,4-3.7,5.7 C84.5,88,82.8,89,80.5,90c-1.8,0.7-4.4,1.5-9.3,1.7C65.9,91.9,64.4,92,51,92c-13.4,0-14.9-0.1-20.2-0.3c-4.9-0.2-7.5-1-9.3-1.7 c-2.3-0.9-4-2-5.8-3.7c-1.8-1.8-2.8-3.4-3.7-5.7c-0.7-1.8-1.5-4.4-1.7-9.3C10.1,65.9,10,64.3,10,51s0-14.9,0.3-20.2 c0.2-4.9,1-7.5,1.7-9.3c0.9-2.3,2-4,3.7-5.8c1.8-1.8,3.4-2.8,5.8-3.7c1.8-0.7,4.4-1.5,9.3-1.7C35.4,10.1,37.2,10,46.5,10L46.5,10z M77.7,18.3c-3.3,0-6,2.7-6,6c0,3.3,2.7,6,6,6s6-2.7,6-6S81,18.3,77.7,18.3L77.7,18.3z M51,25.3c-14.2,0-25.7,11.5-25.7,25.7 S36.8,76.7,51,76.7c14.2,0,25.7-11.5,25.7-25.7S65.2,25.3,51,25.3L51,25.3z M51,34.3c9.2,0,16.7,7.5,16.7,16.7 c0,9.2-7.5,16.7-16.7,16.7c-9.2,0-16.7-7.5-16.7-16.7C34.3,41.8,41.8,34.3,51,34.3z"
		/>
	</svg>
);

export default IconInstagram;
