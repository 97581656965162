import React, { useCallback } from "react";
import PropTypes from "prop-types";
import IconFacebookShare from "app/pages/.shared/static/icons/IconFacebookShare";
import waitForFacebookApi from "app/utils/waitForFacebookApi";
import "./facebook-share-button.scss";

const FacebookShareButton = ({ label, quote, link, onClick }) => {
	const shareOnFacebook = useCallback(() => {
		// eslint-disable-next-line no-undef
		FB.ui(
			{
				method: "share",
				display: "dialog",
				mobile_iframe: true,
				quote: quote,
				href: link,
			},
			() => {
				if (typeof onClick === "function") {
					onClick();
				}
			}
		);
	}, [quote, link]);

	return (
		<div className="facebook-share-button" onClick={shareOnFacebook}>
			<span className="facebook-share-button__icon">
				<IconFacebookShare height={20} width={20} />
			</span>
			<span className="facebook-share-button__label">{label}</span>
		</div>
	);
};

FacebookShareButton.propTypes = {
	link: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	quote: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	onClick: PropTypes.func,
};

export default waitForFacebookApi(React.memo(FacebookShareButton));
