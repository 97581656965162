import React from "react";

const IconPaypalFooter = props => (
	<svg data-name="Layer 1" viewBox="0 0 83.2 98.18" {...props}>
		<path
			fill="currentcolor"
			style={{
				isolation: "isolate",
			}}
			d="M75,24.94c1.2-7.63,0-12.83-4.14-17.54C66.29,2.22,58.08,0,47.57,0H17.05a4.36,4.36,0,0,0-4.31,3.69L0,84.26a2.62,2.62,0,0,0,2.59,3H21.46l-1.3,8.24a2.29,2.29,0,0,0,2.26,2.65H38.3A3.82,3.82,0,0,0,42.07,95l.16-.8,3-19,.19-1.05a3.82,3.82,0,0,1,3.77-3.22h2.38c15.38,0,27.43-6.25,31-24.33C84,39,83.22,32.73,79.33,28.29A15.43,15.43,0,0,0,75,24.94h0"
			opacity={0.68}
		/>
		<path
			fill="currentcolor"
			style={{
				isolation: "isolate",
			}}
			d="M75,24.94c1.2-7.63,0-12.83-4.14-17.54C66.29,2.22,58.08,0,47.57,0H17.05a4.36,4.36,0,0,0-4.31,3.69L0,84.26a2.62,2.62,0,0,0,2.59,3H21.46l4.73-30-.15.94a4.36,4.36,0,0,1,4.3-3.69h9c17.59,0,31.36-7.14,35.38-27.8.12-.61.22-1.2.31-1.79"
			opacity={0.7}
		/>
		<path
			fill="currentcolor"
			d="M31.27,25.05a3.87,3.87,0,0,1,2.13-2.86,3.8,3.8,0,0,1,1.65-.37H59a49.8,49.8,0,0,1,7.89.58c.69.11,1.36.24,2,.38s1.29.31,1.9.49l.91.28A21.94,21.94,0,0,1,75,24.94c1.2-7.63,0-12.83-4.14-17.54C66.29,2.22,58.08,0,47.57,0H17.05a4.36,4.36,0,0,0-4.31,3.69L0,84.26a2.62,2.62,0,0,0,2.59,3H21.46l4.73-30Z"
		/>
	</svg>
);

export default IconPaypalFooter;
