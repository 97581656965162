import React from "react";

const SvgIconTickSimple = props => (
	<svg viewBox="0 0 14.601 11.231" {...props}>
		<path
			fill="currentColor"
			d="M.168 9.177a.543.543 0 0 1 0-.786L.955 7.6a.543.543 0 0 1 .786 0l.056.056 3.089 3.313a.271.271 0 0 0 .393 0L12.8 3.168h.056a.543.543 0 0 1 .786 0l.786.786a.543.543 0 0 1 0 .786l-8.981 9.323a.543.543 0 0 1-.786 0L.281 9.346z"
			transform="translate(0 -3)"
		/>
	</svg>
);

export default SvgIconTickSimple;
