import React from "react";

const IconCrossDark = props => (
	<svg viewBox="0 0 512 512" {...props}>
		<path
			fill="currentColor"
			d="M507.3,7.7c6.3,6.3,6.3,16.5,0,22.8L280.5,257.3l226.6,226.6c6.3,6.3,6.3,16.5,0,22.8 c-3.1,3.2-7.3,4.7-11.4,4.7s-8.2-1.6-11.4-4.7L256,278.4L27.7,506.7c-3.1,3.2-7.3,4.7-11.4,4.7s-8.2-1.6-11.4-4.7 c-6.3-6.3-6.3-16.5,0-22.8l226.6-226.6L4.7,30.5c-6.3-6.3-6.3-16.5,0-22.8s16.5-6.3,22.8,0L256,236.2L484.5,7.7 C490.8,1.4,501,1.4,507.3,7.7z"
		/>
	</svg>
);

export default IconCrossDark;
