import React from "react";

const IconEuro = props => (
	<svg id="Calque_1" x={0} y={0} viewBox="0 0 19.5 19" xmlSpace="preserve" {...props}>
		<style>{".st0{fill:currentColor}"}</style>
		<path
			d="M9.8,1.5c4.4,0,8,3.6,8,8s-3.6,8-8,8s-8-3.6-8-8S5.3,1.5,9.8,1.5 M9.8,0.5c-5,0-9,4-9,9s4,9,9,9s9-4,9-9 S14.7,0.5,9.8,0.5L9.8,0.5z"
			className="st0"
		/>
		<path
			d="M13.1,14.3c-0.5,0.3-1.4,0.6-2.4,0.6c-1.5,0-2.9-0.6-3.7-1.7c-0.4-0.5-0.7-1.2-0.9-2h-1v-1.1H6 C6,10,6,9.9,6,9.8c0-0.2,0-0.3,0-0.5H5.2V8.3h1c0.2-0.8,0.6-1.5,1.1-2.1c0.9-1,2.1-1.6,3.5-1.6c0.9,0,1.7,0.2,2.3,0.5l-0.4,1.7 c-0.4-0.2-1-0.4-1.7-0.4c-0.7,0-1.4,0.2-1.9,0.8c-0.2,0.2-0.4,0.6-0.5,1h3.8v1.1h-4c0,0.2,0,0.3,0,0.5c0,0.1,0,0.2,0,0.2h4v1.1 H8.6c0.1,0.5,0.3,0.8,0.5,1.1c0.5,0.5,1.2,0.8,2,0.8c0.7,0,1.4-0.2,1.7-0.4L13.1,14.3z"
			className="st0"
		/>
	</svg>
);

export default IconEuro;
