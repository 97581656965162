import React from "react";

const SvgIconParrainage = props => (
	<svg viewBox="0 0 20 20" {...props}>
		<path
			fill="currentColor"
			d="M13.67,1.13A3.63,3.63,0,1,0,17.3,4.76,3.62,3.62,0,0,0,13.67,1.13Zm.28,6a1.9,1.9,0,0,1-1.54-.71,2,2,0,0,1-.41-1h-.43V4.9h.37V4.79c0-.07,0-.15,0-.22h-.38V4.08H12a2.18,2.18,0,0,1,.45-1A1.87,1.87,0,0,1,14,2.41a2.27,2.27,0,0,1,.93.19l-.18.79A1.8,1.8,0,0,0,14,3.23a.91.91,0,0,0-.76.36,1,1,0,0,0-.22.49H14.6v.49H13a1.69,1.69,0,0,0,0,.23v.1H14.6v.49H13a1.13,1.13,0,0,0,.22.54,1,1,0,0,0,.81.33,1.77,1.77,0,0,0,.71-.16l.15.77A2.2,2.2,0,0,1,14,7.1Z"
		/>
		<path
			fill="none"
			stroke="currentColor"
			strokeMiterlimit={10}
			d="M12,7.47a3.24,3.24,0,1,1-.83-4.28"
		/>
		<path
			fill="none"
			stroke="currentColor"
			strokeLinecap="round"
			strokeLinejoin="round"
			d="M3,16.75l.15-1.43A7.08,7.08,0,0,1,6.3,10.17h0a4.86,4.86,0,0,0,5.94.06l.08-.06h0a6,6,0,0,1,3.42,5.17l.06,1.41-.44.26A11.84,11.84,0,0,1,3.2,16.88Z"
		/>
	</svg>
);

export default SvgIconParrainage;
