import React from "react";

const IconBaby = props => (
	<svg id="Calque_3" x={0} y={0} viewBox="7 5 25 23.6" xmlSpace="preserve" {...props}>
		<style>{".st0{fill:currentColor}"}</style>
		<path
			d="M19.1,23.2c-5.2,0-9.5-3.8-9.6-8.5c0-0.1,0-0.2,0.1-0.3c0.1-0.1,0.2-0.1,0.3-0.1h7.9c0.3,0,0.5-0.2,0.5-0.5 V6.6c0-0.2,0.2-0.4,0.4-0.4l0,0c0.1,0,0.2,0,0.3,0c5.3,0,9.6,3.8,9.6,8.6C28.7,19.4,24.4,23.2,19.1,23.2z M10.4,15.2 c0.3,4,4.1,7.1,8.7,7.1c4.8,0,8.7-3.4,8.7-7.7c0-4.2-3.8-7.6-8.6-7.7v6.8c0,0.8-0.6,1.4-1.4,1.4H10.4z"
			className="st0"
		/>
		<path
			d="M7.7 11.3c.5-.7 2.1.7 2.3 1.6l.1.3c.1.6.2.9.3 1.5 0 .2-.1.5-.4.5 0 0 0 0-.1 0-.2 0-.4-.2-.4-.4-.1-.6-.2-.9-.3-1.5l-.1-.3C8.9 12.4 8.6 12.3 8 12 7.5 11.8 7.5 11.5 7.7 11.3zM13.9 28.1c-1.7 0-3.1-1.4-3.1-3.1 0-1.7 1.4-3.1 3.1-3.1 1.7 0 3.1 1.4 3.1 3.1C17 26.7 15.6 28.1 13.9 28.1zM13.9 22.7c-1.2 0-2.2 1-2.2 2.2 0 1.2 1 2.2 2.2 2.2 1.2 0 2.2-1 2.2-2.2C16.1 23.8 15.1 22.7 13.9 22.7zM24.4 28.1c-1.7 0-3.1-1.4-3.1-3.1 0-1.7 1.4-3.1 3.1-3.1 1.7 0 3.1 1.4 3.1 3.1C27.6 26.7 26.2 28.1 24.4 28.1zM24.4 22.7c-1.2 0-2.2 1-2.2 2.2 0 1.2 1 2.2 2.2 2.2 1.2 0 2.2-1 2.2-2.2C26.7 23.8 25.7 22.7 24.4 22.7z"
			className="st0"
		/>
	</svg>
);

export default IconBaby;
