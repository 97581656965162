import React from "react";

const IconAmex = props => (
	<svg id="Layer_1" x={0} y={0} viewBox="0 0 45 45" xmlSpace="preserve" {...props}>
		<style>{".st0{fill:currentColor}"}</style>
		<path
			d="M19.8 25.6v5.3H21v-1.8h1.6c1.1 0 1.9-.7 1.9-1.8 0-1-.7-1.8-1.8-1.8L19.8 25.6zM23.3 27.4c0 .4-.2.7-.7.7H21v-1.4h1.5C23 26.7 23.3 27 23.3 27.4zM18 25.6L16.7 27.3 15.4 25.6 13.9 25.6 16 28.2 13.9 30.8 15.4 30.8 16.7 29.1 18 30.9 19.5 30.9 17.4 28.2 19.5 25.6zM29.8 27.3c0-1-.7-1.7-1.8-1.7h-2.8v5.3h1.2l.1-1.9h.6l1.6 1.9h1.5l-1.8-2C29.3 28.7 29.8 28.1 29.8 27.3zM28 27.9h-1.5v-1.3l1.5.1c.5 0 .7.3.7.6S28.5 27.9 28 27.9zM13.5 26.6L13.5 25.6 9.3 25.6 9.3 30.9 13.5 30.9 13.5 29.8 10.5 29.8 10.5 28.7 13.4 28.7 13.4 27.6 10.5 27.6 10.5 26.6z"
			className="st0"
		/>
		<path
			d="M0,0v17.3l1.4-3.4h3.2l0.6,1.4v-1.4h4l0.5,1.3l0.5-1.3h16.9v0.3v0.2c0.2-0.1,0.3-0.1,0.5-0.2 c0.2-0.1,0.3-0.2,0.5-0.2c0.2,0,0.4-0.1,0.6-0.1s0.5,0,0.7,0h5.2l0.6,1.4v-1.4h3.2l0.7,1.1v-1.1h3.7v7.9h-3.4l-0.8-1.1v1h-4.9 l-0.4-1.1h-0.8l-0.4,1.1h-2.9c-0.2,0-0.5,0-0.7,0s-0.4,0-0.6-0.1c-0.1-0.1-0.3-0.1-0.4-0.2c-0.1-0.1-0.3-0.1-0.4-0.2v0.4v0.2h-4.9 l-0.7-1v1H3.9l-0.4-1.1H2.7l-0.4,1.1H0v23.1h44.9V31.8c-0.5,0.3-1.1,0.4-1.7,0.6H28.3l-0.7-1v1H24v-2.1c-0.5,0.2-1.1,0.3-1.8,0.3 h0.2v1.7h-5l-0.7-1l-0.7,1H8v-7.9h8l0.7,1l0.7-1h27.5c0-0.1,0-0.1,0-0.1V0H0z"
			className="st0"
		/>
		<path
			d="M34.8 26.6L34.8 25.6 30.6 25.6 30.6 30.9 34.8 30.9 34.8 29.8 31.8 29.8 31.8 28.7 34.7 28.7 34.7 27.6 31.8 27.6 31.8 26.6zM43.3 27.8h-1.2c-.3 0-.5-.2-.5-.5s.2-.5.5-.5h2.3l.5-1.1c0 0 0 0 0 0h-2.7c-1.1 0-1.8.7-1.8 1.6 0 1 .6 1.5 1.6 1.5h1.2c.3 0 .5.2.5.5.1.4-.1.6-.5.6h-2.7V31h2.6c.9 0 1.6-.5 1.8-1.3 0-.2 0-.4 0-.7C44.8 28.2 44.2 27.8 43.3 27.8zM37.2 26.8h2.3l.5-1.1h-2.7c-1.1 0-1.8.7-1.8 1.6 0 1 .6 1.5 1.6 1.5h1.2c.3 0 .5.2.5.5.1.4-.2.6-.5.6h-2.7V31h2.6c1.1 0 1.8-.7 1.8-1.7s-.6-1.5-1.6-1.5h-1.2c-.3 0-.5-.2-.5-.5S36.9 26.8 37.2 26.8zM4.4 19.4l.4 1.1h1.3l-2.3-5.3H2.3L0 20.5h1.3l.5-1.1H4.4zM2.7 17.2l.4-1.1.4 1.1L4 18.4H2.2L2.7 17.2zM23.8 16.9c0-1-.7-1.7-1.8-1.7h-2.8v5.3h1.2l.1-1.9h.6l1.6 1.9h1.5l-1.8-2C23.3 18.3 23.8 17.7 23.8 16.9zM22 17.5h-1.5v-1.3l1.5.1c.5 0 .7.3.7.6S22.5 17.5 22 17.5zM25.8 15.3L24.6 15.3 24.6 18 24.6 20.5 25.8 20.5 25.8 18.1zM31.1 20.5l.5-1.1h2.6l.5 1.1H36l-2.3-5.3h-1.6l-2.3 5.3H31.1zM32.6 17.2l.4-1.1.4 1.1.5 1.2h-1.8L32.6 17.2zM13 20.5L13 15.2 11 15.2 9.8 18.8 8.6 15.2 6.6 15.2 6.6 20.5 7.8 20.5 7.8 16.2 9.2 20.5 10.3 20.5 11.8 16.2 11.8 20.5zM18.3 16.2L18.3 15.2 14.1 15.2 14.1 20.5 18.3 20.5 18.3 19.4 15.3 19.4 15.3 18.3 18.2 18.3 18.2 17.2 15.3 17.2 15.3 16.2zM37.8 17.3L39.9 20.5 41.4 20.5 41.4 15.2 40.2 15.2 40.2 18.7 39.9 18.2 38 15.2 36.4 15.2 36.4 20.5 37.6 20.5 37.6 16.9zM26.7 17.8L26.7 17.8c0 1.7.9 2.7 2.4 2.7l.5-1.1h-.3c-.9 0-1.4-.6-1.4-1.5v-.1c0-.9.5-1.5 1.4-1.5h1.3v-1.1h-1.4C27.6 15.2 26.7 16.3 26.7 17.8z"
			className="st0"
		/>
	</svg>
);

export default IconAmex;
