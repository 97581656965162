import React from "react";

const IconEngagementVoyageException = props => (
	<svg viewBox="0 0 291.89 333.1" {...props}>
		<path
			fill="currentColor"
			d="M291.82,294.32l-34.1-9.84c-7.82-2.25-15.71-4.3-23.43-6.85-3.94-1.3-6-.46-8,3.23-8.39,15.69-17.07,31.22-25.66,46.81-.86,1.56-1.79,3.08-3.14,5.39-3.79-8.75-7.27-16.73-10.72-24.73-9-20.75-18-41.47-26.81-62.28-1.32-3.1-2.65-4.28-6.2-3.88a93,93,0,0,1-16.43.38c-3.28-.22-4.6.7-5.85,3.64-11.67,27.36-23.52,54.64-35.33,81.95-.59,1.37-1.23,2.72-2.17,4.81-1.18-2-2.13-3.43-3-5-8.59-15.59-17.25-31.14-25.64-46.83-2-3.76-4-4.8-8.28-3.5-18,5.46-36,10.52-54.07,15.71-.77.23-1.57.39-3.06.75.64-1.73,1-3,1.59-4.26q20.46-47.1,41-94.17c1.48-3.36,1.43-5.83-.64-9.08-45.76-71.64-5.52-167,77.64-184.32,4-.84,8.12-1.53,12.18-2.29h25c7.09,1.6,14.28,2.85,21.24,4.85A120.92,120.92,0,0,1,264.51,106.9c3.15,27.3-2.21,53-16.39,76.65-1.81,3-2.12,5.27-.66,8.6,14.07,31.95,27.91,64,41.8,96C290,289.92,290.74,291.72,291.82,294.32Zm-16.34-14.51-36.14-83.13c-18.87,23.12-42.61,37.66-71.67,43.94C178,264.49,188,287.79,198.48,312.05c1.45-2.42,2.4-3.92,3.26-5.48,5.7-10.33,11.35-20.69,17.07-31,4.14-7.46,8.83-9.44,17-7.11,9.52,2.7,19.05,5.54,28.61,8.35C267.8,277.81,271.22,278.63,275.48,279.81ZM144.26,233.72A112.36,112.36,0,0,0,143.62,9c-61.53.12-112,51-111.77,112.64S82.88,234,144.26,233.72ZM93.14,311.86l30.5-70.68c-28.86-5.48-52.86-19.1-72.59-41.85L16.1,279.74c2.29-.58,3.68-.88,5-1.28q17-4.87,34-9.8c9.1-2.62,13.36-.84,18,7.58Q81.83,292,90.51,307.7C91.21,308.93,92,310.09,93.14,311.86Z"
			transform="translate(.07 .04)"
		/>
		<path
			fill="currentColor"
			d="M241.5,122a98,98,0,0,1-196-.16c.16-54.72,43.85-98,98.7-97.82C197.78,24.16,241.43,68.16,241.5,122Zm-96.83,89c48-.14,87.92-40.68,87.74-89.15-.17-49.75-40.2-89.06-90.5-88.89-48.12.16-87.51,40.5-87.31,89.4C54.78,172.1,94.5,211.16,144.67,211Z"
			transform="translate(.07 .04)"
		/>
		<path
			fill="currentColor"
			d="M200.57,102.46c-3.19,3.12-5.74,5.63-8.3,8.12-4.18,4.06-8,8.63-12.64,12-6.67,4.89-7.43,10.65-5.62,18.15,2.16,9,3.28,18.19,5,28.18-9.92-5.23-19.23-9.66-28-14.94-5.12-3.08-9.11-2.81-14,.15-7.25,4.35-14.93,8-22.43,11.93-1.41.75-2.85,1.46-5.27,2.69,1.75-10.57,2.84-20.14,5-29.45,1.6-6.71.4-11.22-5-15.89C102,117,95.65,109.46,88.73,102.25c11.23-1.7,22.61-3.63,34-5,3.23-.39,4.54-1.9,5.76-4.42,5-10.27,10-20.49,15.5-31.58,5.46,11,10.61,21.14,15.48,31.39,1.34,2.83,2.82,4.23,6.14,4.64C177,98.71,188.22,100.59,200.57,102.46Z"
			transform="translate(.07 .04)"
		/>
	</svg>
);

export default IconEngagementVoyageException;
