import React from "react";

const IconPlay = props => (
	<svg viewBox="0 0 106.5 106.5" {...props}>
		<circle cx={53.25} cy={53.25} r={53.25} fill="#fff" opacity={0.85} />
		<path fill="currentColor" d="M38.13 79.13L38.13 29.38 76.38 54.88 38.13 79.13z" />
	</svg>
);

export default IconPlay;
