import React from "react";

const IconDeposit = props => (
	<svg viewBox="0 0 23.338 16.536" {...props}>
		<path
			fill="currentColor"
			d="M8.612-.694A6.454,6.454,0,0,0,2.8,2.96H-7.113A1.159,1.159,0,0,0-8.269,4.117V14.685a1.16,1.16,0,0,0,1.156,1.156H10a1.16,1.16,0,0,0,1.156-1.156V11.692A6.455,6.455,0,0,0,8.612-.694ZM-7,4.233H2.346A6.721,6.721,0,0,0,2.161,5.77c0,.15.012.289.012.428H-7ZM9.888,14.583H-7V8.569H2.8a6.459,6.459,0,0,0,5.817,3.654A6.648,6.648,0,0,0,9.888,12.1Zm-1.272-3.4h0a5.416,5.416,0,1,1,5.412-5.424A5.416,5.416,0,0,1,8.616,11.183Z"
			transform="translate(8.269 .694)"
			strokeWidth={0}
		/>
		<path
			fill="currentColor"
			d="M25.022,3H24V8.109a.511.511,0,0,0,.248.438L26.8,10.08l.526-.876L25.022,7.82Z"
			transform="translate(-7.512 -1.113)"
			strokeWidth={0}
		/>
	</svg>
);

export default IconDeposit;
