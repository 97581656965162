import React from "react";

const IconEngagementExpertsEcoute = props => (
	<svg viewBox="0 0 66.24 72.5" {...props}>
		<path
			fill="none"
			stroke="currentColor"
			strokeMiterlimit={10}
			strokeWidth={3}
			d="M29.85,2.5h0c8,0,15.1,2.5,19.4,7.3"
			transform="translate(-.81 -1)"
		/>
		<path
			fill="none"
			stroke="currentColor"
			strokeMiterlimit={10}
			strokeWidth={3}
			d="M49.35,9.8c6.6,7.3,5.8,14.6,4.5,29.8a67.69,67.69,0,0,1-5.2,15.7c-4,8.9-10.4,16.7-19.1,16.7-4.6,0-9-3.3-12.7-7.4l-3.7-4.8c-3.7-5.3-6.8-9-9.1-18.3L2.65,31.1c-1-7.5.2-14,3.8-18.8C11,6,18.95,2.6,29.95,2.5"
			transform="translate(-.81 -1)"
		/>
		<path
			fill="currentColor"
			d="M20.15,7.4C21,22,14.45,35,3.15,35.5c-1.8-7,2.2-22,2.2-22l10.5-8Z"
			transform="translate(-.81 -1)"
		/>
		<path
			fill="currentColor"
			d="M16 4.5c0 11.8 27 26.2 38.2 26.8C56 24.3 52 13.1 52 13.1L39.45 2.8zM62.95 31.9h0a4.06 4.06 0 0 1 4.1 4s0 .05 0 .08l-.1 8a4.06 4.06 0 0 1-4 4.1H61.65c-2.3 5.1-8.1 13-22.2 14.6A3.66 3.66 0 0 1 36 65.4h-6.8a3.59 3.59 0 0 1-3.6-3.58s0 0 0 0V60.6A3.59 3.59 0 0 1 29.13 57H36a3.69 3.69 0 0 1 3.3 2.1c11.2-1.3 16.3-6.9 18.5-11.1a4.07 4.07 0 0 1-3.3-4l.1-8a4.06 4.06 0 0 1 4-4.1h.68"
			transform="translate(-.81 -1)"
		/>
	</svg>
);

export default IconEngagementExpertsEcoute;
