import React from "react";

const SvgIconPlaneBordered = props => (
	<svg viewBox="0 0 19.995 20" {...props}>
		<g data-name="Groupe 961">
			<path
				fill="currentColor"
				d="M168.222 35.4a1.425 1.425 0 0 0-1.044-.4 3.248 3.248 0 0 0-1.577.49 31.086 31.086 0 0 0-5.348 4.156L151.2 38.8a2.011 2.011 0 0 0-1.618.578l-.731.73a.768.768 0 0 0 .212 1.238l6.593 3.145c-.592.708-1.1 1.372-1.57 2.032l-2.355-.346a1.582 1.582 0 0 0-1.363.449l-.692.687a.769.769 0 0 0 .134 1.2l2.428 1.522a1.39 1.39 0 0 0 1.352 1.353l1.522 2.429a.769.769 0 0 0 1.2.136l.691-.689a1.593 1.593 0 0 0 .449-1.365l-.345-2.358a29.996 29.996 0 0 0 2.033-1.568l3.144 6.592a.77.77 0 0 0 .565.428.829.829 0 0 0 .129.011.772.772 0 0 0 .546-.227l.722-.726a1.986 1.986 0 0 0 .586-1.614l-.848-9.067a31.189 31.189 0 0 0 4.152-5.345c.492-.812.736-1.979.086-2.625zm-1.4 1.818a30.121 30.121 0 0 1-4.2 5.341.767.767 0 0 0-.212.605l.879 9.418a.466.466 0 0 1-.1.335l-3.103-6.517a.768.768 0 0 0-.541-.423.8.8 0 0 0-.154-.015.77.77 0 0 0-.509.193 29.105 29.105 0 0 1-3.045 2.385.769.769 0 0 0-.331.749l.4 2.885-.008.008-1.293-2.065a.714.714 0 0 0-.742-.363.769.769 0 0 0-.356-.737l-2.045-1.281.036-.035 2.832.416a.764.764 0 0 0 .749-.33 30.157 30.157 0 0 1 2.388-3.048.77.77 0 0 0-.247-1.2l-6.514-3.106a.538.538 0 0 1 .337-.1l9.416.879a.768.768 0 0 0 .607-.213 29.962 29.962 0 0 1 5.342-4.2 1.855 1.855 0 0 1 .663-.247 1.944 1.944 0 0 1-.253.661z"
				data-name="Trac\xE9 933"
				transform="translate(-148.62 -35.001)"
			/>
		</g>
	</svg>
);

export default SvgIconPlaneBordered;
