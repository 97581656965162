import React from "react";

const SvgIconPicture = props => (
	<svg data-name="Calque 1" viewBox="0 0 629.85 565.29" {...props}>
		<path
			fill="currentColor"
			d="M827.33,795.29H197.48V230H827.33ZM239.58,753.18H785.22V272.11H239.58Z"
			transform="translate(-197.48 -230)"
		/>
		<circle cx={201.57} cy={188.7} r={64.31} fill="currentColor" />
		<path
			fill="currentColor"
			d="M669,443.56c-22,5.39-107.24,67.83-178.32,144,14.91,15,29.23,35.52,41.66,63.82L431.64,696.34V715.6H752.47v-235C732.63,460.18,706.51,434.34,669,443.56Z"
			transform="translate(-197.48 -230)"
		/>
		<path
			fill="currentColor"
			d="M508.89,715.6c.11-2.44.37-4.85.37-7.32,0-88.39-114.79-160-157.23-160-17.42,0-57,19.7-79.69,46.58V715.6Z"
			transform="translate(-197.48 -230)"
		/>
	</svg>
);

export default SvgIconPicture;
