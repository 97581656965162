import React from "react";

const SvgIconMessengerWhite = props => (
	<svg viewBox="0 0 41.24 41.24" {...props}>
		<path
			fill="currentColor"
			d="M50,29.38c-11.39,0-20.62,8.55-20.62,19.09a18.57,18.57,0,0,0,7.68,14.87v7.28l7-3.85a22.1,22.1,0,0,0,5.92.8c11.39,0,20.62-8.55,20.62-19.1S61.39,29.38,50,29.38ZM52,55.09l-5.25-5.6-10.25,5.6,11.27-12,5.38,5.6,10.12-5.6Z"
			transform="translate(-29.38 -29.38)"
		/>
	</svg>
);

export default SvgIconMessengerWhite;
