import React from "react";

const SvgIconLogout = props => (
	<svg viewBox="0 0 19.5 19" {...props}>
		<defs>
			<style>
				{
					".cls-1{fill:none;stroke:currentColor;stroke-linecap:round;stroke-miterlimit:10;stroke-width:1.5px}"
				}
			</style>
		</defs>
		<g id="Calque_4" data-name="Calque 4">
			<path
				d="M13.8 2.74a8 8 0 1 1-12 7A8 8 0 0 1 6.38 2.52M9.75 8.01L9.75.99"
				className="cls-1"
			/>
		</g>
	</svg>
);

export default SvgIconLogout;
