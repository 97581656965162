import React from "react";

const SvgIconWarning = props => (
	<svg viewBox="0 0 86.4 76.5" {...props}>
		<path
			fill="currentColor"
			d="M85.6,67.3L48.5,3c-1.7-2.9-5.4-3.9-8.3-2.2C39.3,1.3,38.5,2.1,38,3L0.8,67.3 c-1.7,2.9-0.7,6.6,2.2,8.3c0.9,0.5,2,0.8,3,0.8h74.2c3.4,0,6.1-2.7,6.1-6.1C86.4,69.3,86.1,68.3,85.6,67.3z M81.2,70.9 c-0.2,0.3-0.5,0.5-0.9,0.5H6.1c-0.6,0-1-0.5-1-1c0-0.2,0-0.4,0.1-0.5L42.3,5.6c0.3-0.5,0.9-0.7,1.4-0.4c0.2,0.1,0.3,0.2,0.4,0.4 l37.1,64.3C81.4,70.2,81.4,70.6,81.2,70.9z M45.7,50.1h-5.1v-27h5.1V50.1z M46.6,58.5c0,1.9-1.5,3.4-3.4,3.4s-3.4-1.5-3.4-3.4 s1.5-3.4,3.4-3.4C45.1,55.2,46.6,56.7,46.6,58.5z"
		/>
	</svg>
);

export default SvgIconWarning;
