import React from "react";

const IconFacebook = props => (
	<svg viewBox="0 0 21.6 46.28" {...props}>
		<path
			d="M18.3,10.25v6.37H13.64v7.79H18.3V47.57h9.59V24.42h6.44s.6-3.74.89-7.82H27.93V11.27A2.26,2.26,0,0,1,30,9.4h5.23V1.29h-7.1C18.07,1.29,18.3,9.09,18.3,10.25Z"
			transform="translate(-13.64 -1.29)"
			fill="currentColor"
		/>
	</svg>
);

export default IconFacebook;
