import React from "react";

const IconEmail = props => (
	<svg viewBox="0 0 494.54 355.63" {...props}>
		<path
			fill="currentColor"
			d="M671.46 576.25a31.27 31.27 0 001-7.75V277.6L502.69 451.43zM177.92 277.63V568.51a31.56 31.56 0 001 7.73l168.77-124.8zM425.19 467.94a53.86 53.86 0 0038.65-16.29L660 250.83a31.32 31.32 0 00-19-6.44H209.42a31.34 31.34 0 00-19 6.45L386.52 451.65A53.89 53.89 0 00425.19 467.94zM483.15 471.39a81.36 81.36 0 01-57.91 24.18h-.05a81.34 81.34 0 01-58-24.18l-170.39 126A31.38 31.38 0 00209.42 600H641a31.21 31.21 0 0012.58-2.64z"
			transform="translate(-177.92 -244.38)"
		/>
	</svg>
);

export default IconEmail;
