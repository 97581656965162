import React from "react";

const IconPaperplane = props => (
	<svg viewBox="0 0 20 20" {...props}>
		<path
			fill="currentColor"
			d="M17.9,4C18,4,18,4.1,18,4.2l-7.1,11.6c-0.1,0.1-0.2,0.2-0.3,0.2c-0.1,0-0.1,0-0.2-0.1l-1.9-1.3l-2,1.3 c0,0-0.1,0-0.1,0c-0.1,0-0.2-0.1-0.2-0.2V13l9-6.7L4.5,11.9l-2.3-1.6C1.9,10.1,2,9.8,2.2,9.6L17.9,4C17.9,4,17.9,4,17.9,4 M17.9,3 c-0.1,0-0.3,0-0.4,0.1L1.9,8.7C1.4,8.9,1.1,9.3,1,9.8c-0.1,0.5,0.2,1,0.6,1.3l2.3,1.6c0.2,0.1,0.4,0.2,0.6,0.2c0.2,0,0.3,0,0.5-0.1 l0.2-0.1c0,0.1-0.1,0.2-0.1,0.3v2.7c0,0.7,0.6,1.2,1.2,1.2c0.2,0,0.5-0.1,0.7-0.2l1.4-0.9l1.4,0.9c0.2,0.2,0.5,0.2,0.8,0.2 c0.5,0,0.9-0.2,1.2-0.7l7.1-11.6c0.2-0.3,0.2-0.8,0-1.1C18.7,3.2,18.3,3,17.9,3L17.9,3z"
		/>
	</svg>
);

export default IconPaperplane;
