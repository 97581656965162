import React from "react";

const SvgIconPaiementComptant = props => (
	<svg viewBox="0 0 168 111.5" {...props}>
		<rect
			width={157}
			height={100.5}
			x={5.5}
			y={5.5}
			fill="#fff"
			stroke="currentcolor"
			strokeMiterlimit={10}
			strokeWidth={11}
			rx={4.51}
			ry={4.51}
		/>
		<path fill="currentcolor" d="M5.5 28H161.69V48.5H5.5z" />
	</svg>
);

export default SvgIconPaiementComptant;
